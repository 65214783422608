import styled from "styled-components";
import { Box, BoxProps } from "@mui/material";

const StyledBox = styled(Box)`
  && {
    width: 600px;
    height: 785px;
    background-color: #ffffff;
    border-radius: 16px;
    box-shadow: 0px 4px 16px rgba(51, 51, 51, 0.1);
    padding: 50px;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
  }
`;

interface Props extends BoxProps {
  children: React.ReactElement;
}

export const FormBox = ({ children, ...rest }: Props) => {
  return <StyledBox {...rest}>{children}</StyledBox>;
};
