import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { AppDispatch } from "store";
import { useDispatch } from "react-redux";
import { SubHeader, Input } from "components";
import { Header, Item, ItemLabel, ItemValue } from "./components";
import { updateProfile } from "modules/profile";
import { requestGetProfile, useSelectProfileDetails } from "modules/profile";
import { GeneralProps } from "./types";
import { theme } from "themes";

const StyledSubheader = styled(SubHeader)`
  && {
    font-size: 20px;
    margin-top: 50px;
    font-family: ${theme.fontFamily.secondary};
    color: ${theme.colors.black};
  }
`;

const StyledName = styled(ItemLabel)`
  padding: 4px 0 5px;
`;

interface Props extends GeneralProps {}

export const ProfileDetails = ({ onChange }: Props) => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const { name, email, role } = useSelectProfileDetails();

  const _onChange = (value: string | boolean, type: string) => {
    const action = () => dispatch(updateProfile({ [type]: value }));
    onChange(action);
  };

  useEffect(() => {
    dispatch(requestGetProfile());
  }, [dispatch]);

  const mediaQuery = window.matchMedia("(max-width: 768px)");

  return (
    <>
      <Header
        title={`Profile Details`}
        subtitle="This information is employed by the AI email assistant to produce
        replies that are aware of the business."
      />
      <Item>
        <StyledName>{`Name:`}</StyledName>
        <Input
          textFieldProps={{
            id: "outlined-search",
            type: "search",
            variant: "standard",
            value: name,
            onChange: (event) => _onChange(event.target.value, "username"),
          }}
        />
      </Item>
      <Item>
        <ItemLabel>{`Email:`}</ItemLabel>
        <ItemValue>{`${email}`}</ItemValue>
      </Item>
      <Item>
        <ItemLabel>{`Role:`}</ItemLabel>
        <ItemValue>{`${
          role.slice(0, 1).toUpperCase() + role.slice(1)
        }`}</ItemValue>
      </Item>
      <StyledSubheader>Linked Account</StyledSubheader>
      <Item>
        {!mediaQuery.matches && <ItemLabel>{`Google Account:`}</ItemLabel>}
        <ItemValue>{`${email}`}</ItemValue>
      </Item>
      <ItemValue
        onClick={() => navigate("/signout")}
        style={{ textDecoration: "underline", cursor: "pointer" }}
      >{`${"Sign out"}`}</ItemValue>
    </>
  );
};
