import { SelectBox, Description } from "components";

interface Props {
  value: string;
  onChange: (value: string) => void;
}

const items = [
  // { value: "Detect", label: "Auto Detect Tone" },
  {
    value: "Neutral",
    label: "Neutral Tone",
  },
  {
    value: "Persuasive",
    label: "Persuasive Tone",
  },
  {
    value: "Friendly",
    label: "Friendly Tone",
  },
  {
    value: "Professional",
    label: "Professional Tone",
  },
];

export const CommunicateStyle = ({ onChange, value }: Props) => {
  return (
    <>
      {
        <Description style={{ marginBottom: 20 }}>
          {"How would you like Clevermail to communicate?"}
        </Description>
      }
      <SelectBox
        value={!!value && value}
        onChange={(event: any) => onChange(event.target.value)}
        label="Choose a tone/style"
        items={items}
      />
    </>
  );
};
