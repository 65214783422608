import styled from "styled-components";
import { Header, Box } from "components";
import { theme } from "themes";

export interface HeaderProps {
  title: string;
}

const StyledBox = styled(Box)`
  margin-bottom: 20px;
`;

const Title = styled(Header)`
  && {
    color: ${theme.colors.black};
    font-size: 32px;
    font-weight: 600;
    line-height: 26px;
    padding-bottom: 35px;
    cursor: default;
    font-family: ${theme.fontFamily.secondary};
  }
`;

const Border = styled.div`
  height: 6px;
  background-color: #d9d9d9;
  border-radius: 2px;
  color: ${theme.colors.black};
`;

export const PayListHeader = ({ title }: HeaderProps) => {
  return (
    <StyledBox>
      <Title>{title}</Title>
      <Border />
    </StyledBox>
  );
};
