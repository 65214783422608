export interface INTIAL_STATE {
  step: number;
  isLoading?: boolean;
  companyDescription: string;
  productDetails: string;
  currentRole: string;
  language: string;
  policiesFaq: string;
  onBoardingComplete: boolean;
  paymentStatus?: string;
}

export const initialState: INTIAL_STATE = {
  step: 1,
  companyDescription: "",
  productDetails: "",
  currentRole: "",
  language: "English",
  policiesFaq: "",
  onBoardingComplete: false,
};
