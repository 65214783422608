import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { requestGetProfile } from "modules/profile";
import { AppDispatch } from "store";
import { updateOnboarding } from "modules/onboarding";
import { setOnboardingStep, useSelectOnboarding } from "modules/onboarding";
import { requestUpdateOnboarding } from "modules/onboarding";
import { Container, Image } from "components";
import { FormBox, GetStarted, ProductInfo, CustomerFaqs } from "./components";
import { GTMTrack } from "hooks/useGTMTracking";
import { useAnalytics, AmplitudeEvents } from "../../hooks/useRudderAnalytics";

export const OnBoarding = () => {
  const dispatch: AppDispatch = useDispatch();
  const onboarding = useSelectOnboarding();
  const { sendRudderstackEvent } = useAnalytics();

  const activeStep = onboarding?.step || 1;
  const maxStep = 4;

  useEffect(() => {
    dispatch(requestGetProfile());
  }, [dispatch]);

  const onHandleAction = () => {
    if (activeStep < maxStep) {
      dispatch(setOnboardingStep(activeStep + 1));
      if (activeStep === maxStep - 1) {
        GTMTrack("OnboardingComplete");
        dispatch(setOnboardingStep(maxStep - 1));
        dispatch(requestUpdateOnboarding({ sendRudderstackEvent }));
      }
    }
  };

  const onChange = (value: string, type: string) => {
    dispatch(
      updateOnboarding({
        [type]: value,
      })
    );
  };

  const setActiveStep = (step: number) => dispatch(setOnboardingStep(step));
  const onHandleBack = () => activeStep >= 0 && setActiveStep(activeStep - 1);
  const onHandleSkip = () => dispatch(setOnboardingStep(maxStep - 1));

  useEffect(() => {
    sendRudderstackEvent({
      eventName: AmplitudeEvents.ON_ONBOARDING_STARTED,
    });
  }, []);

  return (
    <Container>
      <Image
        fileName="logo-large"
        style={{ marginTop: "40px", marginBottom: "25px" }}
      />
      <FormBox
        sx={{
          margin: 10,
        }}
      >
        <>
          {activeStep === 1 && (
            <GetStarted
              onHandleAction={onHandleAction}
              onHandleBack={onHandleBack}
              onHandleSkip={onHandleSkip}
              activeStep={activeStep}
              maxStep={maxStep}
              onChangeSelect={(value) => onChange(value, "currentRole")}
              onChangeTextArea={(value) =>
                onChange(value, "companyDescription")
              }
              textAreaValue={onboarding.companyDescription}
              selectValue={onboarding.currentRole}
            />
          )}
          {activeStep === 2 && (
            <ProductInfo
              onHandleAction={onHandleAction}
              onHandleBack={onHandleBack}
              activeStep={activeStep}
              maxStep={maxStep}
              textAreaValue={onboarding.productDetails}
              selectValue={onboarding.language}
              onChangeSelect={(value) => onChange(value, "language")}
              onChangeTextArea={(value) => onChange(value, "productDetails")}
            />
          )}
          {activeStep === 3 && (
            <CustomerFaqs
              onHandleAction={onHandleAction}
              onHandleBack={onHandleBack}
              activeStep={activeStep}
              maxStep={maxStep}
              textAreaValue={onboarding.policiesFaq}
              onChangeTextArea={(value) => onChange(value, "policiesFaq")}
            />
          )}
        </>
      </FormBox>
    </Container>
  );
};
