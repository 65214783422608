import styled, { css } from "styled-components";
import { Box } from "components";
import React, { useState } from "react";
import MenuIcon from "@mui/icons-material/Menu"; // Importing MenuIcon

interface StyledBoxProps {
  isOpen: boolean;
}

const mobileStyles = css<StyledBoxProps>``;

const StyledBox = styled(Box)<StyledBoxProps>`
  width: ${(props) => (props.isOpen ? "270px" : "60px")};
  height: 100%;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  z-index: 10;
  background-color: #ffffff;
  border-radius: 16px;
  box-shadow: 0px 4px 8px rgba(51, 51, 51, 0.1);
  transition: all 0.3s ease-in-out;
  position: relative;
  @media (max-width: 768px) {
    ${mobileStyles}
  }
`;

const SidebarItemsContainer = styled.div<StyledBoxProps>`
  transform: ${(props) =>
    props.isOpen ? "translateX(0)" : "translateX(-400px)"};
  transition: transform 0.3s ease-in-out;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const HamburgerButton = styled.button<StyledBoxProps>`
  background: none;
  border: none;
  cursor: pointer;
  margin-top: 30px;
  padding: 0;
  transform: ${(props) =>
    props.isOpen ? "translateX(17px)" : "translateX(17px)"};
  transition: transform 0.3s ease-in-out;
  margin-bottom: 15px;
  align-self: flex-start;
  @media (max-width: 768px) {
    margin-top: 35px;
  }
`;

interface Props {
  children: React.ReactElement[];
}

export const SideBar = ({ children }: Props) => {
  const [isOpen, setIsOpen] = useState(true);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <StyledBox isOpen={isOpen}>
      <HamburgerButton isOpen={isOpen} onClick={toggleSidebar}>
        <MenuIcon />
      </HamburgerButton>
      <SidebarItemsContainer isOpen={isOpen}>{children}</SidebarItemsContainer>
    </StyledBox>
  );
};
