import { useSelector } from "react-redux";
import { INTIAL_STATE } from "./initial-state";
import {
  selectIsLoading,
  selectOnboarding,
  selectOnboardingInformation,
  selectOnboardingComplete,
  selectPaymentStatus,
} from "./selectors";

export const useSelectIsLoading = (): boolean | undefined =>
  useSelector(selectIsLoading);

export const useSelectOnboarding = (): INTIAL_STATE =>
  useSelector(selectOnboarding);

export const useSelectOnboardingInformation = () =>
  useSelector(selectOnboardingInformation);

export const useSelectOnboardingComplete = () =>
  useSelector(selectOnboardingComplete);

export const useSelectPaymentStatus = () => useSelector(selectPaymentStatus);
