import { emailAdapter } from "./adapter";
import { RootState } from "reducers";

export const {
  selectIds: selectEmailIds,
  selectEntities: selectEmailEntities,
  selectAll: selectAllEmails,
  selectById: selectEmailById,
} = emailAdapter.getSelectors((state: RootState) => state.emails);

export const selectSearch = (state: RootState) => state.emails.search;

export const selectEmailState = (state: RootState) => state.emails.emailState;

export const selectLoading = (state: RootState) => state.emails.loading;

export const selectSelectedEmail = (state: RootState) =>
  state.emails.selectedEmail;

export const selectNextPageToken = (state: RootState) =>
  state.emails.nextPageToken;

export const selectEmailUseCase = (state: RootState) =>
  state.emails.selectedEmail?.instructions;

export const selectEmailReply = (state: RootState) =>
  state.emails?.selectedEmail?.reply;

export const selectEmailReplyLoadingState = (state: RootState) =>
  state.emails.emailReplyLoading;
