import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./initial-state";
import { requestLogin, requestLogout, requestIsAuthenticated } from "./actions";
import { openUrl, reloadEmailPages } from "utils";

const authenticationSlice = createSlice({
  name: "authenticationSlice",
  initialState: initialState,
  reducers: {
    setAllowedCode(state, action) {
      state.allowedCode = action.payload;
    },
    setRedirectUrl(state, action) {
      state.redirectUrl = action.payload;
    },
    setAuthentication(state, action) {
      state.authenticated = action.payload;
    },
    setConsent(state, action) {
      state.consent = action.payload;
    },
    setLastVisitedPage(state, action) {
      state.lastVisitedPage = action.payload;
    },
    setReferrer(state, action) {
      state.referrer = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(requestLogin.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(requestLogin.rejected, (state) => {
      state.isLoading = false;
      state.authenticated = false;
    });
    builder.addCase(requestLogin.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      if (payload) {
        state.redirectUrl = initialState.redirectUrl;
        state.consent = false;
        openUrl(payload, () => (window.location.href = payload));
      }
    });
    builder.addCase(requestLogout.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(requestLogout.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(requestLogout.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      if (payload) {
        state.redirectUrl = initialState.redirectUrl;
        state.authenticated = false;
      }
    });
    builder.addCase(requestIsAuthenticated.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(requestIsAuthenticated.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(requestIsAuthenticated.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      if (payload !== undefined && payload && !state.authenticated) {
        reloadEmailPages();
      }
      if (payload !== undefined) state.authenticated = payload;
    });
  },
});

export const {
  setRedirectUrl,
  setAuthentication,
  setAllowedCode,
  setLastVisitedPage,
  setConsent,
  setReferrer,
} = authenticationSlice.actions;

export const authenticationReducer = authenticationSlice.reducer;
