import { useState, useImperativeHandle, forwardRef } from "react";
import styled from "styled-components";
import {
  TextareaAutosize,
  TextareaAutosizeProps,
} from "@mui/base/TextareaAutosize";
import { theme } from "themes";

const StyledTextareaAutosize = styled(TextareaAutosize)(
  () => `
    font-family: IBM Plex Sans, sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 8px 12px;
    border-radius: 8px;
    border-color: #d9d9d9;
    color: ${theme.colors.black};
    font-family: ${theme.fontFamily.primary};
  `
);

interface Props extends TextareaAutosizeProps {
  validation?: (value: string) => [boolean, string];
}

export const TextArea = forwardRef((props: Props, ref) => {
  const { validation, value, ...rest } = props;
  const [errorMessage, setErrorMessage] = useState("");

  const validate = () => {
    if (!validation) {
      return;
    }
    const [isFieldValid, fieldErrorMessage] = validation(value as string);
    if (!isFieldValid) {
      setErrorMessage(fieldErrorMessage);
    } else {
      setErrorMessage("");
    }
    return isFieldValid;
  };

  // Allow parent component to call getErrorMessage through the ref
  useImperativeHandle(ref, () => ({
    validate: validate,
  }));

  const onEndEditingField = () => {
    validate();
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        margin: 0,
      }}
    >
      <span
        style={{
          color: "red",
          visibility: errorMessage ? "visible" : "hidden",
          marginBottom: 10,
          marginTop: 5,
        }}
      >
        {errorMessage}
      </span>
      <StyledTextareaAutosize
        onBlur={onEndEditingField}
        value={value}
        {...rest}
      ></StyledTextareaAutosize>
    </div>
  );
});
