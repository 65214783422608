import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "store";
import { useNavigate, useLocation } from "react-router-dom";
import { requestGetPaymentPlan, useSelectPaymentPlans } from "modules/profile";
import useGTMTracking from "hooks/useGTMTracking";
import { AmplitudeEvents, useAnalytics } from "../../hooks/useRudderAnalytics";

export const PaymentFinished = () => {
  const dispatch: AppDispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);
  const paymentId = query.get("session_id");
  const paymentPlans = useSelectPaymentPlans();
  const { sendRudderstackEvent } = useAnalytics();
  useGTMTracking("PaymentFinished");

  useEffect(() => {
    if (paymentId) {
      dispatch(requestGetPaymentPlan());
    }
  }, [paymentId, dispatch]);

  useEffect(() => {
    if (!!paymentPlans) {
      sendRudderstackEvent({
        eventName: AmplitudeEvents.ON_PAYMENT_FINISHED,
      });

      navigate("/settings", { replace: true });
    }
  }, [paymentPlans]);

  return null;
};
