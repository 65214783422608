import { Email, ReturnObject } from "./types";
import axios from "middlewear/axiosconfig";
import { REL_EMAIL_URL } from "env-urls";

const fetchEmails = async (
  nextPageToken?: string,
  maxResults?: number,
  search?: string,
  type?: string
): Promise<ReturnObject> => {
  const params = {
    nextPageToken,
    maxResults,
    search,
    type,
  };

  const response = await axios.get(`${REL_EMAIL_URL}/getinboxmessages`, {
    params,
  });
  return response.data;
};

const fetchTokenLimit = async (): Promise<{ tokenLimit: number }> => {
  const response = await axios.get(`${REL_EMAIL_URL}/request-token-limit`);
  return response.data;
};

const fetchEmail = async (id: string): Promise<Email> => {
  const response = await axios.get(`${REL_EMAIL_URL}/getinboxmessage?id=${id}`);
  return response.data;
};

const updateEmailThreadStatus = async (
  id: string,
  archive?: boolean
): Promise<Email> => {
  const data = {
    id,
    archive,
  };
  const response = await axios.post(
    `${REL_EMAIL_URL}/requestthreadarchivestatus`,
    data
  );
  return response.data;
};

const updateEmailUseCase = async (
  id: string,
  details: string
): Promise<string> => {
  const data = {
    details,
    id,
  };
  const response = await axios.post(
    `${REL_EMAIL_URL}/updateinboxmessageusecase`,
    data
  );
  return response.data;
};

const generateEmailReply = async (
  id: string,
  details: string
): Promise<{ reply: string; instructions: string; gptInput: string }> => {
  const data = {
    details,
    id,
  };
  const response = await axios.post(
    `${REL_EMAIL_URL}/requestgeneratemailreply`,
    data,
    {
      timeout: 0,
    }
  );
  return response.data;
};

const generateEmail = async (
  details: string
): Promise<{ reply: string; instructions: string; gptInput: string }> => {
  const data = {
    details,
  };
  const response = await axios.post(
    `${REL_EMAIL_URL}/requestgeneratemailreply`,
    data,
    {
      timeout: 0,
    }
  );
  return response.data;
};

const sendEmail = async ({
  rawMessage,
  replyAll,
  id,
  subject,
  to,
}: {
  rawMessage: string;
  replyAll?: boolean;
  id?: string;
  subject?: string;
  to?: string;
}): Promise<void> => {
  const data: any = {
    rawMessage,
    replyAll,
    subject,
    to,
  };
  if (id !== undefined) {
    data.id = id;
  }
  await axios.post(`${REL_EMAIL_URL}/requestcomposemessage`, data, {
    timeout: 0,
  });
  return;
};

const forwardEmail = async (
  id: string,
  forwardTo: string,
  rawMessage: string
): Promise<void> => {
  const data = {
    id,
    forwardTo,
    rawMessage,
  };
  await axios.post(`${REL_EMAIL_URL}/requestforwardmessage`, data, {
    timeout: 0,
  });
  return;
};

const uploadAttachmentAndGetUrl = async (file: File): Promise<void> => {
  const formData = new FormData();
  formData.append("file", file);
  const response = await axios.post(
    `${REL_EMAIL_URL}/requestuploadattachment`,
    formData
  );
  return response.data;
};

export const emailAPI = {
  fetchEmails,
  fetchEmail,
  updateEmailUseCase,
  generateEmailReply,
  generateEmail,
  updateEmailThreadStatus,
  sendEmail,
  forwardEmail,
  uploadAttachmentAndGetUrl,
  fetchTokenLimit,
};
