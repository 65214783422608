import { useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "store";
import styled from "styled-components";
import { Box, Link } from "components";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { SideBar, SideBarItem, SideBarDivider } from "./components";
import { GetStarted } from "./get-started";
import { ProfileDetails } from "./profile-details";
import { PlansSubscriptions } from "./plans-subscriptions";
import { AICustomizations } from "./ai-customisations";
import { useNavigate } from "react-router-dom";
import { PERSON, GRID, COMPUTER, QUESTION_MARK, BOOK } from "images";
import { requestUpdateProfile } from "modules/profile";
import { Support } from "./support";
// import { useParticipateInViralLoops } from "./hooks";
// import { VIRAL_LOOPS_CAMPAIGN_ID } from "env-urls";
import useGTMTracking from "hooks/useGTMTracking";
import { useAnalytics, AmplitudeEvents } from "../../hooks/useRudderAnalytics";
import { Recaptcha } from "../authentication/components/rechaptcha-v3";
import { requestAuthenticateCaptcha } from "modules/authentication";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 100%;
  overflow: hidden;
`;

const Wrapper = styled(Box)`
  flex: 1;
  // flex: 5;
  overflow-y: auto;
  overflow-x: hidden;
  padding-top: 110px;
  padding-bottom: 110px;
  padding-left: 70px;
  padding-right: 70px;
  min-width: 400px;
  white-space: nowrap;
  @media (max-width: 768px) {
    min-width: 0;
    padding-top: 30px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 30px;
  }
`;

const LinkWrapper = styled.div`
  padding: 15px;
`;

export const Settings = () => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const actionTimer = useRef<NodeJS.Timeout | null>(null);
  const TIMER = 1000;
  // const campaign = useParticipateInViralLoops();
  const { sendRudderstackEvent } = useAnalytics();
  const query = new URLSearchParams(location.search);
  const installed = query.get("installed");

  useGTMTracking("SettingsInitiated");

  useEffect(() => {
    if (installed) {
      sendRudderstackEvent({
        eventName: AmplitudeEvents.ON_EXTENSION_INSTALLED,
      });
    } else {
      sendRudderstackEvent({
        eventName: AmplitudeEvents.ON_SETTINGS_LOADED,
      });
    }
  }, [installed]);

  const onChange = (action: () => void) => {
    action();
    if (actionTimer.current) {
      clearTimeout(actionTimer.current);
    }
    actionTimer.current = setTimeout(() => {
      dispatch(requestUpdateProfile({ sendRudderstackEvent }));
      action();
    }, TIMER);
  };

  useEffect(() => {
    // Clear the timer when the location changes
    return () => {
      if (actionTimer.current) {
        dispatch(requestUpdateProfile({ sendRudderstackEvent }));
        clearTimeout(actionTimer.current);
      }
    };
  }, [location, dispatch]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      if (actionTimer.current) {
        dispatch(requestUpdateProfile({ sendRudderstackEvent }));
        clearTimeout(actionTimer.current);
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [dispatch]);

  const handleCaptchaChange = (value: string) => {
    dispatch(requestAuthenticateCaptcha(value));
  };

  return (
    <Container>
      <Recaptcha onVerify={handleCaptchaChange} />
      <SideBar>
        <SideBarItem
          onClick={() => navigate("get-started")}
          title="Get Started"
          iconName={BOOK}
          active={location.pathname.includes("get-started")}
        />
        <SideBarItem
          onClick={() => navigate("profile-details")}
          title="Profile Details"
          iconName={PERSON}
          active={location.pathname.includes("profile-details")}
        />
        <SideBarItem
          onClick={() => navigate("ai-customizations")}
          title="AI Customizations"
          iconName={GRID}
          active={location.pathname.includes("ai-customizations")}
        />
        <SideBarItem
          onClick={() => navigate("plans-subscriptions")}
          title="Plans & Subscriptions"
          iconName={COMPUTER}
          active={location.pathname.includes("plans-subscriptions")}
        />
        <SideBarItem
          onClick={() => navigate("support")}
          title="Support"
          iconName={QUESTION_MARK}
          active={location.pathname.includes("support")}
        />
        <SideBarDivider />
        <LinkWrapper>
          <Link
            activeFileName="email-blue"
            fileName="email"
            href="https://gmail.com"
          >
            Use in Gmail
          </Link>
        </LinkWrapper>
        <LinkWrapper>
          <Link
            activeFileName="secure-globe-blue"
            fileName="secure-globe"
            href="https://clevermail.co/privacy-policy/"
          >
            Our Privacy Policy
          </Link>
        </LinkWrapper>
        {/* {campaign && (
          <div
            style={{
              paddingLeft: 5,
              paddingRight: 5,
              marginTop: 20,
              flex: 1,
              alignItems: "flex-end",
              display: "flex",
              cursor: "default",
            }}
          >
            <form-widget ucid={VIRAL_LOOPS_CAMPAIGN_ID}></form-widget>
          </div>
        )} */}
      </SideBar>
      <Wrapper>
        <Routes>
          <Route path="/" element={<Navigate replace to="get-started" />} />
          <Route path="get-started" element={<GetStarted />} />
          <Route
            path="profile-details"
            element={<ProfileDetails onChange={onChange} />}
          />
          <Route
            path="ai-customizations"
            element={<AICustomizations onChange={onChange} />}
          />
          <Route
            path="plans-subscriptions"
            element={<PlansSubscriptions onChange={onChange} />}
          />
          <Route path="support" element={<Support />} />
        </Routes>
      </Wrapper>
    </Container>
  );
};
