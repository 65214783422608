import styled from "styled-components";
import { theme } from "themes";

const StyledButton = styled.button`
  text-decoration: none;
  padding: 0px 5px;
  background-color: transparent;
  border: none;
  color: ${theme.colors.white};
  font-weight: 800;
  cursor: pointer;
  transition: background-color 0.3s;
  font-family: ${theme.fontFamily.primary}
  line-height: 1.5;
  font-size: 1.05rem;
`;

interface Props {
  content: string;
}

export const PrivatePolicy = ({ content }: Props) => {
  return (
    <>
      <StyledButton
        onClick={() =>
          window.open("https://clevermail.co/privacy-policy/", "_blank")
        }
      >
        Private Policy
      </StyledButton>
    </>
  );
};
