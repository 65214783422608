import { useState, useEffect } from "react";

export interface ImageProps {
  fileName: string;
  alt?: string;
  style?: React.CSSProperties;
}

export const Image = ({ fileName, alt, style }: ImageProps) => {
  const [imageSrc, setImageSrc] = useState("");
  const [srcSet, setSrcSet] = useState("");

  useEffect(() => {
    const loadImage = async () => {
      try {
        const image = await import(`../../images/${fileName}.png`);
        setImageSrc(image.default);

        let srcSetString = "";
        try {
          const image2x = await import(`../../images/${fileName}@2x.png`);
          srcSetString += `${image2x.default} 2x, `;
        } catch (err2x) {}

        try {
          const image3x = await import(`../../images/${fileName}@3x.png`);
          srcSetString += `${image3x.default} 3x`;
        } catch (err3x) {}

        setSrcSet(srcSetString);
      } catch (err) {}
    };

    loadImage();
  }, [fileName]);

  return <img style={style} src={imageSrc} srcSet={srcSet} alt={alt} />;
};
