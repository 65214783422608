import { SelectBox, Description } from "components"; // Adjust the import path

interface Props {
  value: string;
  onChange: (value: string) => void;
}

const items = [
  {
    value: "C-Level Executive",
    label: "C-Level Executive",
  },
  {
    value: "Manager / Director",
    label: "Manager / Director",
  },
  {
    value: "Project Manager",
    label: "Project Manager",
  },
  {
    value: "Customer Support",
    label: "Customer Support",
  },
  {
    value: "Sales",
    label: "Sales",
  },
  {
    value: "Product Manager",
    label: "Product Manager",
  },
  {
    value: "Business Development Professional",
    label: "Business Development Professional",
  },
  {
    value: "Data Analyst / Scientist",
    label: "Data Analyst / Scientist",
  },
  {
    value: "Software Developer",
    label: "Software Developer",
  },
  {
    value: "Designer",
    label: "Designer",
  },
  {
    value: "Content Creator",
    label: "Content Creator",
  },
  {
    value: "Social Media",
    label: "Social Media",
  },
  {
    value: "Administrative",
    label: "Administrative",
  },
  {
    value: "Consultant",
    label: "Consultant",
  },
  {
    value: "Entrepreneur / Founder",
    label: "Entrepreneur / Founder",
  },
  {
    value: "Freelancer",
    label: "Freelancer",
  },
  {
    value: "Student",
    label: "Student",
  },
  {
    value: "Educator",
    label: "Educator",
  },
  {
    value: "Researcher",
    label: "Researcher",
  },
  {
    value: "Other",
    label: "Other",
  },
];

export const CurrentRole = ({ value, onChange }: Props) => {
  return (
    <>
      <Description style={{ marginBottom: 20 }}>
        {"What is your current role"}
      </Description>
      <SelectBox
        label="Current role"
        value={value}
        onChange={(event: any) => onChange(event.target.value)}
        items={items}
      />
    </>
  );
};
