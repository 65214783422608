import { useEffect, useState } from "react";
import { HashRouter } from "react-router-dom";
import { AppDispatch } from "store";
import { useDispatch } from "react-redux";
import { AuthenticatedRoutes, UnauthenticatedRoutes } from "./";
import {
  useSelectIsAuthentication,
  requestIsAuthenticated,
  useSelectIsLoading,
} from "modules/authentication";
import { ScreenWrapper } from "components";
import { isExtension } from "../utils";
import { ExtensionRoutes } from "./extension-routes";
import { AnalyticsProvider } from "../hooks/useRudderAnalytics";

export const CustomRoutes = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const isAuthenticated = useSelectIsAuthentication();
  // const isAuthenticated = true;

  const isLoading = useSelectIsLoading();
  const dispatch: AppDispatch = useDispatch();
  const isExt = isExtension();

  useEffect(() => {
    dispatch(requestIsAuthenticated() as any);
  }, [dispatch]);

  useEffect(() => {
    if (isLoading !== undefined) setLoading(isLoading);
  }, [isLoading]);

  if (loading) return null;

  return (
    <HashRouter>
      <AnalyticsProvider>
        <ScreenWrapper>
          {isExt ? (
            <ExtensionRoutes />
          ) : isAuthenticated ? (
            <AuthenticatedRoutes />
          ) : (
            <UnauthenticatedRoutes />
          )}
        </ScreenWrapper>
      </AnalyticsProvider>
    </HashRouter>
  );
};
