import { Email, EMAIL_STATE } from "./types";

interface INTIAL_STATE {
  loading: boolean;
  error?: string;
  selectedEmail?: Email;
  nextPageToken?: string;
  search: string;
  emailState: EMAIL_STATE;
  emailReplyLoading: boolean;
}

export const initialState: INTIAL_STATE = {
  loading: false,
  search: "",
  emailReplyLoading: false,
  emailState: EMAIL_STATE.inbox,
};
