import { MainForm, CurrentRole, BottomButtons } from ".";
import Box from "@mui/material/Box";

interface Props {
  maxStep: number;
  activeStep: number;
  textAreaValue: string;
  selectValue: string;
  onHandleSkip: () => void;
  onHandleBack: () => void;
  onHandleAction: () => void;
  onChangeSelect: (value: string) => void;
  onChangeTextArea: (value: string) => void;
}

export const GetStarted = ({
  maxStep,
  activeStep,
  onHandleSkip,
  onHandleBack,
  onHandleAction,
  onChangeSelect,
  onChangeTextArea,
  textAreaValue,
  selectValue,
}: Props) => {
  return (
    <>
      <MainForm
        maxStep={maxStep}
        activeStep={activeStep}
        onChangeTextArea={onChangeTextArea}
        onHandleSkip={onHandleSkip}
        title="Let's get started"
        subtitle="Give your new AI executive the crucial info and be amazed."
        description="Let's start with a brief description of your company."
        actionButtonText="Next"
        textAreaPlaceholder="E.g. GiveMeTap manufactures eco-friendly water bottles"
        textAreaValue={textAreaValue}
      />
      <Box marginTop="50px">
        <CurrentRole value={selectValue} onChange={onChangeSelect} />
      </Box>
      <BottomButtons
        onHandleBack={onHandleBack}
        onHandleAction={onHandleAction}
        hideBackButton={true}
        actionButtonText={"Next"}
      />
    </>
  );
};
