import styled from "styled-components";
import debounce from "lodash/debounce";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "store";
import {
  requestEmails,
  setSearch,
  setEmailState,
  useSelectSearch,
  EMAIL_STATE,
  useSelectEmailState,
} from "modules/email";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import { useSelectAllEmails, useSelectLoading } from "modules/email";
import { EmailListItem } from "./components";
import { List } from "components";
import { isExtension, openUrl } from "../../../utils";
import { SETTINGS_URL } from "../../../env-urls";

const BoldListSubheader = styled.div`
  font-weight: 800;
  margin-right: 20px;
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  padding-bottom: 20px;
`;

const BottomWrapper = styled.div`
  display: flex;
  flex-direction: row;
  background: white;
  border-radius: 10px;
  position: fixed;
  bottom: 10px;
  right: 30px;
`;

export const EmailList = () => {
  const emails = useSelectAllEmails();
  const search = useSelectSearch();
  const emailState = useSelectEmailState();

  const loading = useSelectLoading();
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();

  const debouncedGetEmails = debounce(() => dispatch(requestEmails()), 100);

  useEffect(() => {
    debouncedGetEmails();
    return () => debouncedGetEmails.cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, search, emailState]);

  const onClickEmail = (id: string) => {
    navigate(`/transcript/${id}`);
  };

  const isExt = isExtension();

  const onExpand = () => openUrl(SETTINGS_URL);

  const onChangeInput = (event: any) => {
    const searchValue = event.target.value;
    dispatch(setSearch(searchValue));
  };

  const sortedEmails = emails.sort(
    (a, b) =>
      new (window as any).Date(parseInt(b.date)) -
      new (window as any).Date(parseInt(a.date))
  );

  const onChangeInbox = (type: EMAIL_STATE) => dispatch(setEmailState(type));

  const activeStyle = { background: "black", color: "white" };

  return (
    <>
      <HeaderWrapper>
        <BoldListSubheader>Email List</BoldListSubheader>
        <button
          style={emailState === EMAIL_STATE.inbox ? activeStyle : {}}
          onClick={() => onChangeInbox(EMAIL_STATE.inbox)}
        >
          INBOX
        </button>
        <button
          style={emailState === EMAIL_STATE.sent ? activeStyle : {}}
          onClick={() => onChangeInbox(EMAIL_STATE.sent)}
        >
          SENT
        </button>
        <button
          style={emailState === EMAIL_STATE.draft ? activeStyle : {}}
          onClick={() => onChangeInbox(EMAIL_STATE.draft)}
        >
          DRAFTS
        </button>
        <button
          style={emailState === EMAIL_STATE.all ? activeStyle : {}}
          onClick={() => onChangeInbox(EMAIL_STATE.all)}
        >
          All
        </button>
        {isExt && <button onClick={onExpand}>{`Open App`}</button>}
        <input
          style={{
            width: "500px",
            height: "30px",
            borderRadius: "15px",
            border: "solid 0.5px black",
            marginLeft: 10,
            paddingLeft: 10,
            paddingRight: 40,
          }}
          placeholder="Search Email"
          value={search}
          onChange={onChangeInput}
        />
        {search && (
          <button
            style={{
              background: "none",
              marginLeft: "-30px",
              border: "none",
              fontSize: "18px",
              zIndex: 10,
            }}
            onClick={() => dispatch(setSearch(""))}
          >
            x
          </button>
        )}
      </HeaderWrapper>
      <List>
        {sortedEmails &&
          sortedEmails.map((email) => (
            <EmailListItem
              key={email.id}
              email={email}
              onClick={onClickEmail}
            />
          ))}
      </List>
      <div
        style={{
          marginTop: "10px",
        }}
      >
        {loading ? (
          <LoadingButton loading={loading} />
        ) : (
          <button
            style={{ width: "100px", height: "50px" }}
            onClick={() => dispatch(requestEmails())}
          >
            Load more
          </button>
        )}
      </div>
      <BottomWrapper>
        <button
          style={{ width: "100px", marginRight: 10, height: "50px" }}
          onClick={() => navigate(`/compose-email`)}
        >
          Compose Email
        </button>
        <button
          style={{ width: "100px", marginRight: 10, height: "50px" }}
          onClick={() => navigate(`/profile`)}
        >
          Profile
        </button>
        <button
          style={{ width: "100px", height: "50px" }}
          onClick={() => navigate(`/signout`)}
        >
          Sign out
        </button>
      </BottomWrapper>
    </>
  );
};
