const BASE_URL = "https://staging-api.clevermail.co";
const REL_AUTH_URL = `auth`;
const REL_EMAIL_URL = `email`;
const REL_PROFILE_URL = `profile`;
const REL_USER_URL = `user`;
const REL_REDIRECT_PAYMENT_URL = `payment-finished`;
const ABS_AUTH_URL = `${BASE_URL}/${REL_AUTH_URL}`;
const ABS_EMAIL_URL = `${BASE_URL}/${REL_EMAIL_URL}`;
const ABS_PROFILE_URL = `${BASE_URL}/${REL_PROFILE_URL}`;

const APP_BASE_URL = "https://staging.clevermail.co";
const SIGN_IN_URL = `${APP_BASE_URL}/#/signin`;
const SETTINGS_CUSTOMIZATIONS_URL = `${APP_BASE_URL}/#/settings/ai-customizations`;
const PAYMENT_REDIRECT_URL = `${APP_BASE_URL}/#/${REL_REDIRECT_PAYMENT_URL}`;
const SETTINGS_URL = `${APP_BASE_URL}/#/settings/get-started`;
const HOME_URL = `${APP_BASE_URL}/#/`;
const STRIPE_KEY =
  "pk_test_51ORMxBGYJkaq5ZjbrmgYw2DoGZ1Hr4hEkLMRNmnxEy6SY9UpJfE7op8t6yNjpKfo2gu1uiCsSybEVMD2RxMZro7p00prybKty1";
const RUDDERSTACK_WRITE_KEY = "2fsrRkFnnsWgbFSkEO7ULefLyFJ";
const VIRAL_LOOPS_CAMPAIGN_ID = "HfebqdgPYVmSeBMWDga9aKQV2cQ";
const CAPTCHA_KEY_V3 = "6LflcgQqAAAAAJ6vm9CMsaEDPHjTr842GrOVUVel";
const CAPTCHA_KEY_V2 = "6LehsQYqAAAAAC6N7DjUPDakHPrx2NHhMp6sQxRk";

export {
  CAPTCHA_KEY_V2,
  CAPTCHA_KEY_V3,
  VIRAL_LOOPS_CAMPAIGN_ID,
  RUDDERSTACK_WRITE_KEY,
  SIGN_IN_URL,
  SETTINGS_CUSTOMIZATIONS_URL,
  PAYMENT_REDIRECT_URL,
  REL_REDIRECT_PAYMENT_URL,
  BASE_URL,
  ABS_AUTH_URL,
  ABS_EMAIL_URL,
  ABS_PROFILE_URL,
  REL_AUTH_URL,
  REL_EMAIL_URL,
  REL_PROFILE_URL,
  REL_USER_URL,
  APP_BASE_URL,
  SETTINGS_URL,
  HOME_URL,
  STRIPE_KEY,
};
