import { SelectBox, Description } from "components";

interface Props {
  value: string;
  onChange: (value: string) => void;
}

const items = [
  // { value: "Detect", label: "Auto Detect" },
  {
    value: "English",
    label: "English(US)",
  },
  {
    value: "English(UK)",
    label: "English(UK)",
  },
  {
    value: "Spanish",
    label: "Spanish",
  },
  {
    value: "French",
    label: "French",
  },
  {
    value: "Portuguese",
    label: "Portuguese",
  },
  {
    value: "Hindi",
    label: "Hindi",
  },
  {
    value: "Mandarin",
    label: "Mandarin",
  },
  {
    value: "Tagalog",
    label: "Tagalog",
  },
];

export const LanguageSelection = ({ onChange, value }: Props) => {
  return (
    <>
      <Description style={{ marginBottom: 20 }}>
        {"What language should Clevermail respond in?"}
      </Description>
      <SelectBox
        value={!!value && value}
        onChange={(event: any) => onChange(event.target.value)}
        label="Choose a language"
        items={items}
      />
    </>
  );
};
