import React, { useState } from "react"; // Import useState from react
import { TextArea, Description, SubHeader } from "components"; // Adjust the import path
import { styled } from "styled-components";

const CharactersLeft = styled(SubHeader)`
  && {
    font-size: 12px;
    white-space: wrap;
    margin-bottom: 25px;
    text-align: right;
    margin-top: 5px;
  }
`;

interface Props {
  maxRows?: number;
  minRows?: number;
  onChangeTextArea: (value: string) => void;
  onValidate?: (value: string) => [boolean, string];
  title?: string;
  textAreaPlaceholder: string;
  textAreaValue?: string;
  errorMessageRef?: any;
  charactersLeft?: number;
}

export const FormTextArea = ({
  title,
  maxRows,
  minRows,
  errorMessageRef,
  onValidate,
  textAreaValue,
  textAreaPlaceholder,
  onChangeTextArea,
  charactersLeft,
}: Props) => {
  // State to manage focus
  const [isFocused, setIsFocused] = useState(false);

  return (
    <>
      {title && <Description>{title}</Description>}
      <TextArea
        maxRows={maxRows || 12}
        minRows={minRows || 8}
        onChange={(event: any) => onChangeTextArea(event.target.value)}
        aria-label="maximum height"
        placeholder={textAreaPlaceholder}
        value={textAreaValue}
        validation={onValidate}
        ref={errorMessageRef}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
      />
      {isFocused && charactersLeft !== undefined && (
        <CharactersLeft>{charactersLeft} characters left</CharactersLeft>
      )}
    </>
  );
};
