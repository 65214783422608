export type ThreadMessage = {
  plainText: string;
  htmlText: string | TrustedHTML;
  htmlChildren?: string | TrustedHTML;
  from: string;
  to: string;
  messageId: string;
};

export type Thread = {
  id: string;
  subject: string;
  snippet: string;
  from: string;
  date: string;
  to: string;
};

export enum EMAIL_STATE {
  inbox = "INBOX",
  draft = "DRAFT",
  all = "ALL",
  sent = "SENT",
}

export type Email = {
  id: string;
  instructions: string;
  gptInput?: string;
  reply: string;
  archived: boolean;
  messages: ThreadMessage[];
  participants: string;
};

export type ReturnObject = {
  list: Array<Thread>;
  nextPageToken: string;
};
