import React, { useEffect, useCallback } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

interface Props {
  onVerify: (token: string) => void;
}

export const Recaptcha = ({ onVerify }: Props) => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log("Execute recaptcha not yet available");
      return;
    }

    const token = await executeRecaptcha("login");
    onVerify(token);
  }, [executeRecaptcha, onVerify]);

  useEffect(() => {
    handleReCaptchaVerify();
  }, []);

  return <></>;
};
