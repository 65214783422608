import TextField, { TextFieldProps } from "@mui/material/TextField";
import { theme } from "themes";

interface Props {
  textFieldProps: TextFieldProps;
}

export const Input = ({ textFieldProps }: Props) => {
  return (
    <TextField
      sx={{
        "& .MuiInputBase-input": {
          color: theme.colors.black,
          fontFamily: theme.fontFamily.primary,
        },
      }}
      {...textFieldProps}
    />
  );
};
