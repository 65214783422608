import {
  createContext,
  useContext,
  useEffect,
  useState,
  useRef,
  ReactNode,
} from "react";
import { RUDDERSTACK_WRITE_KEY } from "../env-urls";
import { RudderAnalytics } from "@rudderstack/analytics-js";
import { useSelectProfileDetails } from "modules/profile";

export interface SendEventProps {
  eventName: string;
  properties?: Record<string, any>;
}

interface IdentifyEventProps {
  email: string;
  traits?: Record<string, any>;
}

interface AnalyticsContextProps {
  sendRudderstackEvent: (props: SendEventProps) => void;
  identifyUser: (props: IdentifyEventProps) => void;
}

interface AnalyticsProviderProps {
  children: ReactNode;
}

const AnalyticsContext = createContext<AnalyticsContextProps | undefined>(
  undefined
);

export const AnalyticsProvider = ({ children }: AnalyticsProviderProps) => {
  const [analytics, setAnalytics] = useState<RudderAnalytics>();
  const [identifyCompleted, setIdentifyCompleted] = useState<boolean>(false);
  const eventQueue = useRef<SendEventProps[]>([]);
  const { email, name } = useSelectProfileDetails();

  useEffect(() => {
    if (!analytics) {
      // const analyticsInstance = new RudderAnalytics();
      // analyticsInstance.load(
      //   RUDDERSTACK_WRITE_KEY,
      //   "https://clevermailfbeu.dataplane.rudderstack.com"
      // );
      // setAnalytics(analyticsInstance);
    }

    const savedQueue = localStorage.getItem("eventQueue");
    if (savedQueue) {
      eventQueue.current = JSON.parse(savedQueue);
    }
  }, [analytics]);

  useEffect(() => {
    if (email) {
      identifyUser({ email, traits: { name } });
    }
  }, [email, name]);

  useEffect(() => {
    if (identifyCompleted) {
      processEventQueue();
    }
  }, [identifyCompleted]);

  const identifyUser = async ({ email, traits }: IdentifyEventProps) => {
    const callback = () => {
      setIdentifyCompleted(true);
    };
    if (analytics) {
      analytics.identify(email, traits, {}, callback);
    }
  };

  const sendRudderstackEvent = ({ eventName, properties }: SendEventProps) => {
    if (identifyCompleted) {
      analytics?.track(eventName, properties);
    } else {
      eventQueue.current.push({ eventName, properties });
      localStorage.setItem("eventQueue", JSON.stringify(eventQueue.current));
    }
  };

  const processEventQueue = () => {
    if (identifyCompleted && analytics) {
      eventQueue.current.forEach((event) => {
        analytics.track(event.eventName, event.properties);
      });
      eventQueue.current = [];
      localStorage.removeItem("eventQueue");
    }
  };

  return (
    <AnalyticsContext.Provider value={{ sendRudderstackEvent, identifyUser }}>
      {children}
    </AnalyticsContext.Provider>
  );
};

export const useAnalytics = (): AnalyticsContextProps => {
  const context = useContext(AnalyticsContext);
  if (!context) {
    throw new Error("useAnalytics must be used within an AnalyticsProvider");
  }
  return context;
};

export enum AmplitudeEvents {
  ON_SETTINGS_UPDATED = "ON_SETTINGS_UPDATED",
  ON_SETTINGS_LOADED = "ON_SETTINGS_LOADED",
  ON_LOGIN_BUTTON_CLICKED = "ON_LOGIN_BUTTON_CLICKED",
  ON_ONBOARDING_COMPLETED = "ON_ONBOARDING_COMPLETED",
  ON_ONBOARDING_STARTED = "ON_ONBOARDING_STARTED",
  ON_PAYMENT_FINISHED = "ON_PAYMENT_FINISHED",
  ON_EXTENSION_INSTALLED = "ON_EXTENSION_INSTALLED",
}
