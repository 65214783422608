import { Routes, Route, Navigate } from "react-router-dom";
import { ExtensionDashboard } from "scenes";

export const ExtensionRoutes = () => {
  return (
    <Routes>
      <Route
        path="*"
        element={<Navigate replace to="/extension-dashboard" />}
      />
      <Route path="/extension-dashboard" element={<ExtensionDashboard />} />
    </Routes>
  );
};
