import { useEffect } from "react";

export const GTMTrack = (eventName: string) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: eventName,
    pagePath: window.location.pathname,
    pageTitle: document.title,
  });
};

const useGTMTracking = (eventName: string) => {
  useEffect(() => {
    GTMTrack(eventName);
  }, []); // Empty dependency array ensures this runs only once on mount
};

export default useGTMTracking;
