import styled from "styled-components";
import { Header, Box, PrimaryButton, SecondaryButton } from "components";
import { theme } from "themes";

const TopButton = styled.div`
  background-color: ${theme.colors.blue};
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  outline: none;
  border-radius: 24px;
  height: 34px;
  width: 146px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: default;
  font-family: ${theme.fontFamily.primary};
`;

const StyledBox = styled(Box)<{ active?: boolean; disabled?: boolean }>`
  max-width: 400px;
  flex: 1;
  height: 460px;
  background-color: #ffffff;
  z-index: ${(props) => (props.active ? "1" : "0")};
  box-shadow: ${(props) =>
    props.active ? "0px 4px 32px rgba(51,51,51,0.1)" : "none"};
  border-radius: 16px;
  padding: 35px;
  display: flex;
  flex-direction: column;
  pointer-events: ${(props) => (props.disabled ? "none" : "initial")};
  opacity: ${(props) => (props.disabled ? "0.5" : "1")};
  @media (max-width: 768px) {
    flex: 1;
    width: initial;
    box-shadow: 0px 4px 32px rgba(51, 51, 51, 0.1);
    margin-bottom: 50px;
  }
`;

const Title = styled(Header)`
  && {
    color: ${theme.colors.black};
    font-size: 20px;
    font-weight: 600;
    line-height: 26px;
    cursor: default;
    font-family: ${theme.fontFamily.secondary};
  }
`;

const Dollar = styled.span`
  color: ${theme.colors.black};
  font-size: 32px;
  font-family: ${theme.fontFamily.primary};
  font-weight: 600;
  align-self: flex-start;
  margin-top: 10px;
  cursor: default;
`;

const Price = styled.span`
  color: ${theme.colors.black};
  font-size: 72px;
  font-family: ${theme.fontFamily.primary};
  font-weight: 600;
  height: 80px;
  cursor: default;
`;

const PriceMonth = styled.div`
  color: ${theme.colors.black};
  font-size: 20px;
  font-family: ${theme.fontFamily.primary};
  margin-left: 10px;
  margin-bottom: 12px;
  cursor: default;
`;

const Description = styled.div`
  && {
    font-size: 20px;
    font-size: 20px;
    color: ${theme.colors.black};
    font-family: ${theme.fontFamily.primary};
    margin-top: 40px;
    cursor: default;
    white-space: wrap;
    @media (max-width: 768px) {
      margin-top: 10px;
      margin-bottom: 30px;
    }
  }
`;

const ActionsWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 30px;
  margin-top: 30px;
  @media (max-width: 768px) {
    margin-bottom: 0px;
  }
`;

const TopBox = styled(Box)<{ active?: boolean }>`
  display: flex;
  justify-content: flex-end;
  height: 70px;
  @media (max-width: 768px) {
    margin-bottom: 0px;
    height: initial;
    margin-top: ${(props) => (props.active ? "30px" : "initial")};
    margin-bottom: ${(props) => (props.active ? "30px" : "initial")};
  }
`;

const CurrencyBox = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: row;
  padding-top: 10px;
  padding-bottom: 10px;
`;

interface Props {
  active?: boolean;
  title: string;
  price: string;
  description: string;
  actionText: string;
  mostPopular?: boolean;
  disabled?: boolean;
  onActionPressed: () => void;
}

export const PayBox = ({
  active,
  title,
  price,
  description,
  actionText,
  mostPopular,
  disabled,
  onActionPressed,
}: Props) => {
  return (
    <StyledBox disabled={disabled} active={active}>
      <TopBox active={mostPopular}>
        {mostPopular && <TopButton>Coming Soon</TopButton>}
      </TopBox>
      <Title>{title}</Title>
      <CurrencyBox>
        <Dollar> $ </Dollar>
        <Price> {price} </Price>
        <PriceMonth> /month </PriceMonth>
      </CurrencyBox>
      <Description>{description}</Description>
      <ActionsWrapper>
        {active ? (
          <PrimaryButton onClick={onActionPressed}>{actionText}</PrimaryButton>
        ) : (
          <SecondaryButton onClick={onActionPressed}>
            {actionText}
          </SecondaryButton>
        )}
      </ActionsWrapper>
    </StyledBox>
  );
};
