import styled from "styled-components";
import { Header as Title, SubHeader, PrimaryButton } from "components";
import { theme } from "themes";

const StyledTitle = styled(Title)`
  && {
    font-size: 32px;
    cursor: default;
    font-family: ${theme.fontFamily.secondary};
    color: ${theme.colors.black};
    font-weight: 700;
    white-space: wrap;
  }
`;

const StyledSubtitle = styled(SubHeader)`
  && {
    margin-top: 5px;
    margin-bottom: 40px;
    cursor: default;
    font-family: ${theme.fontFamily.primary};
    color: ${theme.colors.black};
    white-space: wrap;
    @media (max-width: 768px) {
      margin-top: 15px;
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

interface Props {
  title: string;
  subtitle?: string;
  actionText?: string;
  onActionClick?: () => void;
}

const ActionButton = styled(PrimaryButton)`
  && {
    margin-bottom: 30px;
  }
`;

export const Header = ({
  title,
  subtitle,
  actionText,
  onActionClick,
}: Props) => {
  return (
    <Wrapper>
      <div>
        <StyledTitle style={{ fontSize: 32 }}>{title}</StyledTitle>
        <StyledSubtitle>{subtitle}</StyledSubtitle>
      </div>
      {actionText && (
        <ActionButton onClick={onActionClick}>{actionText}</ActionButton>
      )}
    </Wrapper>
  );
};
