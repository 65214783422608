import styled from "styled-components";
import { Thread } from "modules/email/types";
import { formatFrom } from "./helpers";
import { formatDateTime } from "../../../../utils";

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  cursor: pointer;
  padding-top: 5px;
  padding-bottom: 5px;
  border-bottom: solid 1px lightgray;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;

const From = styled.div`
  font-size: 10;
  font-weight: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
`;

const Subject = styled.div`
  font-size: 14;
  font-weight: 800;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 2;
`;

const Summary = styled.div`
  font-size: 12;
  font-weight: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 2;
`;

const Date = styled.div`
  font-size: 12;
  font-weight: normal;
  white-space: nowrap;
  font-weight: bold;
`;

interface Props {
  email: Thread;
  onClick: (id: string) => void;
}

export const EmailListItem = ({ email, onClick }: Props) => {
  const formattedDate = new (window as any).Date(
    parseInt(email.date)
  ).toString();

  return (
    <ListWrapper key={email.id} onClick={() => onClick(email.id)}>
      <Row>
        <From>{formatFrom(email.from)}</From>
        <Subject dangerouslySetInnerHTML={{ __html: email.subject }} />
        <Summary dangerouslySetInnerHTML={{ __html: email.snippet }} />
        <Date> {formatDateTime(formattedDate)} </Date>
      </Row>
    </ListWrapper>
  );
};
