import { useEffect } from "react";
import styled from "styled-components";
import { Image, SecondaryButton, SubHeader, Box } from "components";
import { useDispatch } from "react-redux";
import { AppDispatch } from "store";
import { Header } from "./components";
import { requestGetProfile } from "modules/profile";

const ContentWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const StyledTitle = styled(SubHeader)`
  && {
    font-size: 20px;
    font-weight: 600;
    margin-right: 30px;
  }
  @media (max-width: 768px) {
    && {
      margin-right: 0px;
      margin-bottom: 20px;
    }
  }
`;

const Description = styled(SubHeader)`
  && {
    font-size: 16px;
    white-space: wrap;
  }
`;

export const GetStarted = () => {
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    dispatch(requestGetProfile());
  }, [dispatch]);

  const onGoToStore = () => {
    const url =
      "https://chromewebstore.google.com/detail/clevermail/afcehapodanikojlimiaflnakjfcgfpn";
    window.open(url, "_blank");
  };

  const onGoToGmail = () => {
    const url = "https://gmail.com";
    window.open(url, "_blank");
  };

  return (
    <div
      style={{
        maxWidth: "1000px",
      }}
    >
      <Header
        title={`Get Started with Clevermail`}
        subtitle="You're just a few steps away from transforming your email experience."
      />
      <Box marginTop={"25px"} marginBottom={"50px"}>
        <ContentWrapper>
          <StyledTitle>Step 1: Install the Clevermail Extension</StyledTitle>
          <SecondaryButton onClick={onGoToStore}>
            Install Extension
          </SecondaryButton>
        </ContentWrapper>
        <Description>
          Begin by adding Clevermail to your Chrome browser to integrate
          powerful AI capabilities directly into your Gmail.
        </Description>
      </Box>
      <Box marginBottom={"20px"}>
        <Box marginBottom={"20px"}>
          <StyledTitle>Step 2: Discover Clevermail in Action</StyledTitle>
        </Box>
        <Description>
          Watch our quick guide to see how Clevermail can supercharge your email
          productivity.
        </Description>
      </Box>
      <div
        style={{
          width: "100%",
          height: "600px",
          borderRadius: "10px",
          position: "relative",
          overflow: "hidden",
          marginBottom: "50px",
          marginRight: "50px",
        }}
      >
        <Image
          style={{ width: "100%", height: "100%", position: "absolute" }}
          fileName="get-started-placeholder"
        />
        <iframe
          src="https://www.loom.com/embed/77cc840ac1b8468c9452d55d1949a6cc?sid=91f92a8d-3a33-4141-8063-faf035ea4d79"
          style={{
            zIndex: 1,
            position: "absolute",
            width: "100%",
            height: "100%",
          }}
        ></iframe>
      </div>
      <Box marginBottom={"25px"}>
        <ContentWrapper>
          <StyledTitle>Step 3: Dive In and Start Writing</StyledTitle>
          <SecondaryButton onClick={onGoToGmail}>Open Gmail</SecondaryButton>
        </ContentWrapper>
        <Description>
          Now that you're set up, open Gmail, click on Clevermail, and craft
          your first AI-powered email.
        </Description>
      </Box>
    </div>
  );
};
