import { PrimaryButton, ActionsWrapper, SecondaryButton } from "components";

interface Props {
  onHandleAction: () => void;
  onHandleBack?: () => void;
  actionButtonText: string;
  hideBackButton?: boolean;
}

export const BottomButtons = ({
  onHandleAction,
  onHandleBack,
  actionButtonText,
  hideBackButton,
}: Props) => {
  return (
    <ActionsWrapper>
      {onHandleBack ? (
        <SecondaryButton
          width={220}
          style={{ visibility: hideBackButton ? "hidden" : "visible" }}
          onClick={onHandleBack}
        >
          Back
        </SecondaryButton>
      ) : (
        <div />
      )}
      <PrimaryButton width={220} onClick={onHandleAction}>
        {actionButtonText}
      </PrimaryButton>
    </ActionsWrapper>
  );
};
