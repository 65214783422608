import { useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "store";
import { loadStripe } from "@stripe/stripe-js";
import { requestCheckoutSessions, useSelectPaymentId } from "modules/profile";
import { STRIPE_KEY } from "env-urls";
import { isExtension } from "utils";

export const Payment = () => {
  const dispatch: AppDispatch = useDispatch();
  const paymentId = useSelectPaymentId();

  const isExt = isExtension();
  const stripePromise = !isExt && loadStripe(STRIPE_KEY);

  const redirectToCheckout = useCallback(async () => {
    if (paymentId) {
      const stripe = await stripePromise;
      if (stripe) {
        await stripe.redirectToCheckout({ sessionId: paymentId });
      }
    }
  }, [paymentId]);

  useEffect(() => {
    redirectToCheckout();
  }, [redirectToCheckout]);

  useEffect(() => {
    dispatch(requestCheckoutSessions());
  }, [dispatch]);

  return <div> You have are not subscribed to a payment plan </div>;
};
