import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "reducers";
import { selectProfileInformation } from "./selectors";
import { profileApi } from "./api";
import { emailAPI } from "modules/email/api";
import {
  AmplitudeEvents,
  SendEventProps,
} from "../../hooks/useRudderAnalytics";

export const requestGetProfile = createAsyncThunk(
  "profile/request-get-profile",
  async (_, { rejectWithValue }) => {
    try {
      return await profileApi.getProfile();
    } catch (ex) {
      console.error(ex, "profile/requestGetProfile");
      rejectWithValue(ex);
    }
  }
);

export const requestGetTokenLimit = createAsyncThunk(
  "profile/request-get-token-limit",
  async (_, { rejectWithValue }) => {
    try {
      return await emailAPI.fetchTokenLimit();
    } catch (ex) {
      console.error(ex, "profile/requestGetTokenLimit");
      rejectWithValue(ex);
    }
  }
);

export const requestUpdateProfile = createAsyncThunk(
  "profile/request-update-profile",
  async (
    args: {
      sendRudderstackEvent: (props: SendEventProps) => void;
    },
    { getState, rejectWithValue }
  ) => {
    try {
      const state = getState();
      const profile = selectProfileInformation(state as RootState);
      const { sendRudderstackEvent } = args;
      sendRudderstackEvent({
        eventName: AmplitudeEvents.ON_SETTINGS_UPDATED,
      });
      return await profileApi.updateProfile(profile);
    } catch (ex) {
      console.error(ex, "profile/requestUpdateProfile");
      rejectWithValue(ex);
    }
  }
);

export const requestCheckoutSessions = createAsyncThunk(
  "profile/request-checkout-sessions",
  async (_, { rejectWithValue }) => {
    try {
      const response = profileApi.getCheckoutSessions();
      return response;
    } catch (ex) {
      console.error(ex, "request-checkout-sessions");
      rejectWithValue(ex);
    }
  }
);

export const requestGetPaymentPlan = createAsyncThunk(
  "authentication/request-get-payment-plan",
  async (_, { rejectWithValue }) => {
    try {
      const response = await profileApi.getPaymentPlan();
      return response;
    } catch (ex) {
      console.error(ex, "request-get-payment-plan");
      rejectWithValue(ex);
    }
  }
);

export const requestGetOnboardingComplete = createAsyncThunk(
  "profile/request-get-onboarding",
  async (_, { getState, rejectWithValue }) => {
    try {
      return await profileApi.getOnboardingComplete();
    } catch (ex) {
      console.error(ex, "profile/requestGetOnboardingComplete");
      rejectWithValue(ex);
    }
  }
);

export const requestPaymentStatus = createAsyncThunk(
  "profile/request-payment-status",
  async (_, { rejectWithValue }) => {
    try {
      return await profileApi.getPaymentStatus();
    } catch (ex) {
      console.error(ex, "emails/request-payment-status");
      rejectWithValue(ex);
    }
  }
);
