import Collapse from "@mui/material/Collapse";
import { Box, Description, Switch } from "components";
import { FormTextArea } from "../../onboarding/components";

interface Props {
  checked: boolean;
  signOffText: string;
  name: string;
  onChangeSignOff: (value: string) => void;
  onChangeEnableSignOff: () => void;
}

export const SignOff = ({
  name,
  checked,
  signOffText,
  onChangeEnableSignOff,
  onChangeSignOff,
}: Props) => {
  const label = { inputProps: { "aria-label": "Switch demo" } };

  return (
    <>
      <Box marginTop="30px">
        <Description>Enable email sign off?</Description>
        <Switch {...label} onChange={onChangeEnableSignOff} checked={checked} />
        {/* <Collapse in={checked}>
          <FormTextArea
            maxRows={9}
            minRows={3}
            title="How do you want to sign off on emails?"
            textAreaPlaceholder={`Regards,\n\n${name}.`}
            onChangeTextArea={onChangeSignOff}
            textAreaValue={signOffText}
          />
        </Collapse> */}
      </Box>
    </>
  );
};
