import { MainForm } from ".";
import { BottomButtons } from ".";

interface Props {
  maxStep: number;
  activeStep: number;
  textAreaValue: string;
  onHandleBack: () => void;
  onHandleAction: () => void;
  onChangeTextArea: (value: string) => void;
}

export const CustomerFaqs = ({
  maxStep,
  activeStep,
  onHandleBack,
  onHandleAction,
  onChangeTextArea,
  textAreaValue,
}: Props) => {
  return (
    <>
      <MainForm
        maxStep={maxStep}
        activeStep={activeStep}
        onChangeTextArea={onChangeTextArea}
        title="Customer FAQ"
        subtitle="What are your company's customer service policies and style? This helps when addressing customer inquiries or issues."
        description="What are your FAQ or customer service policies?"
        actionButtonText="Confirm & Save"
        textAreaPlaceholder="E.g. We offer a 30-day return policy on unused items."
        textAreaValue={textAreaValue}
      />
      <BottomButtons
        onHandleBack={onHandleBack}
        onHandleAction={onHandleAction}
        hideBackButton={activeStep === 0}
        actionButtonText={"Finish"}
      />
    </>
  );
};
