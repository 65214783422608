export interface INTIAL_STATE {
  email?: string;
  isLoading?: boolean;
  picture?: string;
  generalUseCase: string;
  username: string;
  currentRole: string;
  companyDescription: string;
  productDetails: string;
  policiesFaq: string;
  toneStyle: string;
  language: string;
  sendLogo: boolean;
  paymentPlans: string;
  signOff: string;
  enableSignOff: boolean;
  paymentId?: string;
  tokenLimit?: number;
}

export const initialState: INTIAL_STATE = {
  generalUseCase: "",
  username: "",
  currentRole: "",
  email: "",
  companyDescription: "",
  productDetails: "",
  policiesFaq: "",
  signOff: "",
  language: "English",
  toneStyle: "Neutral",
  paymentPlans: "",
  sendLogo: false,
  enableSignOff: false,
};
