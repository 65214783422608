import { configureStore } from "@reduxjs/toolkit";
import { rootReducer, REDUCER_KEYS } from "./reducers";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage"; // Defaults to localStorage for web

const persistConfig = {
  key: "root",
  storage,
  whitelist: [REDUCER_KEYS.authentication, REDUCER_KEYS.onboarding], // Specify the reducers you want to persist
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store);
export default store;
export type AppDispatch = typeof store.dispatch;
