import { CustomRoutes } from "./navigation/";
import { PersistGate } from "redux-persist/integration/react"; // Import PersistGate
import { persistor } from "./store";
import { CAPTCHA_KEY_V3 } from "env-urls";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

declare global {
  interface Window {
    __extensionMode?: boolean;
  }
}

function App() {
  return (
    <PersistGate loading={null} persistor={persistor}>
      <GoogleReCaptchaProvider reCaptchaKey={CAPTCHA_KEY_V3}>
        <CustomRoutes />
      </GoogleReCaptchaProvider>
    </PersistGate>
  );
}

export default App;
