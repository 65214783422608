import styled from "styled-components";
import { Image, Box } from "components";
import { theme } from "themes";

export interface ListItemProps {
  title: string;
}

const StyledBox = styled(Box)`
  display: flex;
  flex-direction: row;
  margin-top: 25px;
  align-items: center;
  padding-bottom: 10px;
`;

const Item = styled.div`
  && {
    color: ${theme.colors.black};
    font-size: 20px;
    line-height: 26px;
    margin-left: 20px;
    cursor: default;
    white-space: wrap;
    font-family: ${theme.fontFamily.primary};
  }
`;

export const PayListItem = ({ title }: ListItemProps) => {
  return (
    <StyledBox>
      <Image fileName="check-mark-filled" />
      <Item>{title}</Item>
    </StyledBox>
  );
};
