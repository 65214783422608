import { Stepper, FormTextArea } from ".";
import {
  Header,
  SubHeader,
  StepperContainer,
  SkipButton,
  Box,
} from "components";

interface Props {
  onChangeTextArea: (value: string) => void;
  onValidate?: (value: string) => [boolean, string];
  onHandleSkip?: () => void;
  actionButtonText: string;
  activeStep: number;
  maxStep: number;
  title: string;
  subtitle: string;
  description: string;
  textAreaPlaceholder: string;
  textAreaValue?: string;
  errorMessage?: string;
  errorMessageRef?: any;
}

export const MainForm = ({
  onHandleSkip,
  onValidate,
  onChangeTextArea,
  maxStep,
  activeStep,
  title,
  subtitle,
  description,
  textAreaPlaceholder,
  textAreaValue,
  errorMessageRef,
}: Props) => {
  return (
    <>
      <StepperContainer>
        <Stepper steps={maxStep} activeStep={activeStep} />
        <SkipButton onClick={onHandleSkip}>
          {onHandleSkip !== undefined ? `Skip` : ""}
        </SkipButton>
      </StepperContainer>
      <Box marginTop="30px">
        <Header>{title}</Header>
      </Box>
      <Box marginTop="10px">
        <SubHeader>{subtitle}</SubHeader>
      </Box>
      <Box marginTop="20px">
        <FormTextArea
          title={description}
          onChangeTextArea={onChangeTextArea}
          textAreaPlaceholder={textAreaPlaceholder}
          textAreaValue={textAreaValue}
          onValidate={onValidate}
          errorMessageRef={errorMessageRef}
        />
      </Box>
    </>
  );
};
