import { BottomButtons, MainForm, LanguageSelection } from ".";
import Box from "@mui/material/Box";

interface Props {
  maxStep: number;
  activeStep: number;
  textAreaValue: string;
  selectValue: string;
  onHandleBack: () => void;
  onHandleAction: () => void;
  onChangeSelect: (value: string) => void;
  onChangeTextArea: (value: string) => void;
}

export const ProductInfo = ({
  maxStep,
  activeStep,
  onHandleBack,
  onHandleAction,
  textAreaValue,
  selectValue,
  onChangeSelect,
  onChangeTextArea,
}: Props) => {
  return (
    <>
      <MainForm
        maxStep={maxStep}
        activeStep={activeStep}
        onChangeTextArea={onChangeTextArea}
        title="Product Info"
        subtitle="Tell us about your main products or services. This helps when discussing product details or prices in emails."
        description="What are you company products and pricing"
        actionButtonText="Next"
        textAreaPlaceholder={
          "Bottle size & styling:\n300-499ml 500ml-999ml 1,000ml-3,000ml\n\nPricing:\n500ml shorter Insulated Bottle  £18.60 £15.90 £13.50 £12.30 £11.40\n500ml Insulated Bottle  £18.00 £15.40 £13.10 £11.90 £11.10\n700ml Insulated Bottle  £19.90 £17.00 £14.50 £13.10 £12.20"
        }
        textAreaValue={textAreaValue}
      />
      <Box marginTop="50px">
        <LanguageSelection value={selectValue} onChange={onChangeSelect} />
      </Box>
      <BottomButtons
        onHandleBack={onHandleBack}
        onHandleAction={onHandleAction}
        hideBackButton={activeStep === 0}
        actionButtonText={"Next"}
      />
    </>
  );
};
