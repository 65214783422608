import styled from "styled-components";
import { theme } from "themes";

const Container = styled.div`
  color: ${theme.colors.white};
  min-height: 1em;
  white-space: wrap;
  text-align: center;
  line-height: 1.5;
  font-size: 1.25rem;
  font-family: ${theme.fontFamily.primary};
  font-weight: normal;
  font-style: normal;
  margin-top: 1em;
`;

export const Subheader = () => {
  return (
    <Container>
      Save time, enhance your productivity, and focus on what truly matters with
      Clevermail Chrome Extension.
    </Container>
  );
};
