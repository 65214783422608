import styled from "styled-components";
import googleIcon from "./web_light_sq_SI.svg";

const StyledButton = styled.div`
  cursor: pointer;
`;

interface Props {
  onClick: () => void;
}

export const GoogleSignInButton = ({ onClick }: Props) => {
  return (
    <StyledButton onClick={onClick}>
      <img src={googleIcon} alt="Google sign-in" />
    </StyledButton>
  );
};
