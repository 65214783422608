import { useEffect } from "react";
import styled from "styled-components";
import { AppDispatch } from "store";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Header, PayBox, PayList } from "./components";
import { Box } from "components";
import { PAYMENT_PLANS } from "modules/profile";
import { requestGetProfile, useSelectPaymentPlans } from "modules/profile";
import { GeneralProps } from "./types";
import { useSelectPaymentStatus } from "modules/onboarding";

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 70px;
`;

const Row = styled(Box)`
  display: flex;
  flex-direction: row;
  width: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  max-width: 800px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const FeaturesWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 768px) {
    padding: 0px;
  }
`;

interface Props extends GeneralProps {}

export const PlansSubscriptions = ({ onChange }: Props) => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();

  const basicItems = [
    "Process Analysis",
    "Task Management",
    "Time Tracking",
    "Performance Metrics",
    "Customizable Reports",
    "Email Integration",
  ];

  const proItems = [
    "Process Analysis",
    "Task Management",
    "Time Tracking",
    "Performance Metrics",
    "Customizable Reports",
    "Email Integration",
    "Real-time Collaboration",
    "Automated Workflows",
  ];

  const PaylistWrapper = styled.div`
    flex: 1;
    @media (max-width: 768px) {
      margin-top: 50px;
    }
  `;

  const _onChange = (value: PAYMENT_PLANS, paymentStatus?: string) => {
    if (paymentStatus === "trialing") {
      navigate(`/payment?plan=${value}`);
    } else if (paymentStatus === "active") {
      window.open(
        "https://billing.stripe.com/p/login/5kA7sJ9nd65l4mIfYY",
        "_blank"
      );
    }
  };

  const paymentPlans = useSelectPaymentPlans();
  const paymentStatus = useSelectPaymentStatus();

  useEffect(() => {
    dispatch(requestGetProfile());
  }, [dispatch]);

  const mediaQuery = window.matchMedia("(max-width: 768px)");
  const basicActionText = mediaQuery.matches
    ? "Basic"
    : "Get Started with Basic";

  const actionText =
    paymentStatus === "trialing"
      ? "Subscribe to Essential"
      : paymentStatus === "active"
      ? "Manage Subscription"
      : basicActionText;

  return (
    <Container>
      <Wrapper>
        <Header
          title={`Plans & Subscriptions`}
          subtitle="Choose a plan to automatically handle your work emails in under 3
        seconds."
        />
        <Row>
          <PayBox
            active={paymentPlans === PAYMENT_PLANS.BASIC}
            title={"Basic"}
            price="5"
            description="For individuals and small teams"
            actionText={actionText}
            onActionPressed={() =>
              _onChange(PAYMENT_PLANS.BASIC, paymentStatus)
            }
          />
          <PayBox
            disabled
            active={paymentPlans === PAYMENT_PLANS.PRO}
            title="Pro"
            price="19"
            description="For startups and growing businesses"
            actionText={mediaQuery.matches ? "Pro" : "Get Started with Pro"}
            mostPopular
            onActionPressed={() => {}}
          />
        </Row>
        <FeaturesWrapper>
          <Header title="Compare Features" />
          <Row>
            <PayList title="Basic" items={basicItems} />
            <PaylistWrapper>
              <PayList disabled title="Pro" items={proItems} />
            </PaylistWrapper>
          </Row>
        </FeaturesWrapper>
      </Wrapper>
    </Container>
  );
};
