import styled from "styled-components";
import { theme } from "themes";

const Container = styled.h1`
  box-sizing: border-box;
  font-size: 3rem;
  line-height: 1.25;
  text-align: center;
  min-height: 1em;
  white-space: wrap;
  padding-bottom: 0.625em;
  color: ${theme.colors.white};
  font-weight: bold;
  font-family: ${theme.fontFamily.secondary};
  margin: 0px;
  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

export const Header = () => {
  return <Container> Communicate faster </Container>;
};
