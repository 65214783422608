import { createAsyncThunk } from "@reduxjs/toolkit";
import { emailAPI } from "./api";
import { selectNextPageToken } from "./selectors";
import { RootState } from "reducers";
import { selectSearch, selectEmailState } from "./selectors";

export const requestEmails = createAsyncThunk(
  "emails/request-emails",
  async (_, { rejectWithValue, getState }) => {
    try {
      const state = getState();
      const search = selectSearch(state as RootState);
      const type = selectEmailState(state as RootState);
      const nextPageToken = selectNextPageToken(state as RootState);
      const maxResults = 50;
      return await emailAPI.fetchEmails(
        nextPageToken,
        maxResults,
        search,
        type
      );
    } catch (ex) {
      console.error(ex, "emails/request-emails-error");
      rejectWithValue(ex);
    }
  }
);

export const requestEmail = createAsyncThunk(
  "emails/request-email-info",
  async (id: string, { rejectWithValue }) => {
    try {
      return await emailAPI.fetchEmail(id);
    } catch (ex) {
      console.error(ex, "emails/request-emails-error");
      return rejectWithValue(ex);
    }
  }
);

export const requestComposeEmailReply = createAsyncThunk(
  "emails/request-compose-email-reply",
  async (
    {
      id,
      message,
      replyAll,
    }: { id: string; message: string; replyAll?: boolean },
    { rejectWithValue }
  ) => {
    try {
      return await emailAPI.sendEmail({ rawMessage: message, replyAll, id });
    } catch (ex) {
      console.error(ex, "emails/request-compose-email-error");
      return rejectWithValue(ex);
    }
  }
);

export const requestComposeEmail = createAsyncThunk(
  "emails/request-compose-email",
  async (
    { to, message, subject }: { to: string; message: string; subject: string },
    { rejectWithValue }
  ) => {
    try {
      return await emailAPI.sendEmail({ rawMessage: message, subject, to });
    } catch (ex) {
      console.error(ex, "emails/request-compose-email-error");
      return rejectWithValue(ex);
    }
  }
);

export const requestForwardEmail = createAsyncThunk(
  "emails/request-forward-email",
  async (
    {
      id,
      forwardTo,
      rawMessage,
    }: { id: string; forwardTo: string; rawMessage: string },
    { rejectWithValue }
  ) => {
    try {
      return await emailAPI.forwardEmail(id, forwardTo, rawMessage);
    } catch (ex) {
      console.error(ex, "emails/request-forward-email-error");
      return rejectWithValue(ex);
    }
  }
);

export const requestUpdateThreadArchiveStatus = createAsyncThunk(
  "emails/request-update-thread-archive-status",
  async (
    { id, archive }: { id: string; archive: boolean },
    { rejectWithValue }
  ) => {
    try {
      return await emailAPI.updateEmailThreadStatus(id, archive);
    } catch (ex) {
      console.error(ex, "emails/request-update-thread-archive-status");
      return rejectWithValue(ex);
    }
  }
);

export const requestUpdateEmailUseCase = createAsyncThunk(
  "emails/request-update-email-use-case",
  async (
    { id, details }: { id: string; details: string },
    { rejectWithValue }
  ) => {
    try {
      return await emailAPI.updateEmailUseCase(id, details);
    } catch (ex) {
      console.error(ex, "emails/request-email-use-case-error");
      rejectWithValue(ex);
    }
  }
);

export const requestGenerateEmailReply = createAsyncThunk(
  "emails/request-generate-email-reply",
  async (
    { id, details }: { id: string; details: string },
    { rejectWithValue }
  ) => {
    try {
      return await emailAPI.generateEmailReply(id, details);
    } catch (ex) {
      console.error(ex, "emails/request-generate-email-reply-error");
      return rejectWithValue(ex);
    }
  }
);

export const requestGenerateComposeEmail = createAsyncThunk(
  "emails/request-generate-compose-email",
  async ({ details }: { details: string }, { rejectWithValue }) => {
    try {
      return await emailAPI.generateEmail(details);
    } catch (ex) {
      console.error(ex, "emails/request-generate-email-reply-error");
      return rejectWithValue(ex);
    }
  }
);

export const requestUploadAttachmentAndGetUrl = createAsyncThunk(
  "emails/request-upload-attachment-geturl",
  async ({ file }: { file: File }, { rejectWithValue }) => {
    try {
      return await emailAPI.uploadAttachmentAndGetUrl(file);
    } catch (ex) {
      console.error(ex, "emails/request-upload-attachment-geturl-error");
      return rejectWithValue(ex);
    }
  }
);
