import { RootState } from "reducers";

export const selectProfileInformation = (state: RootState) => {
  const {
    currentRole,
    picture,
    policiesFaq,
    productDetails,
    sendLogo,
    toneStyle,
    username,
    companyDescription,
    generalUseCase,
    paymentPlans,
    signOff,
    enableSignOff,
    language,
  } = state.profile;
  return {
    currentRole,
    generalUseCase,
    companyDescription,
    picture,
    policiesFaq,
    productDetails,
    sendLogo,
    toneStyle,
    username,
    paymentPlans,
    signOff,
    enableSignOff,
    language,
  };
};

export const selectProfileUseDetails = (state: RootState) =>
  state.profile.generalUseCase;

export const selectPaymentPlans = (state: RootState) =>
  state.profile.paymentPlans;

export const selectName = (state: RootState) => state.profile.username;

export const selectTokenLimit = (state: RootState) => state.profile.tokenLimit;

export const selectEmail = (state: RootState) => state.profile.email;

export const selectCompanyDescription = (state: RootState) =>
  state.profile.companyDescription;

export const selectRole = (state: RootState) => state.profile.currentRole;

export const selectPaymentId = (state: RootState) => state.profile.paymentId;

export const selectProductDetails = (state: RootState) =>
  state.profile.productDetails;

export const selectPoliciesFaq = (state: RootState) =>
  state.profile.policiesFaq;

export const selectToneStyle = (state: RootState) => state.profile.toneStyle;

export const selectSendlogo = (state: RootState) => state.profile.sendLogo;

export const selectEnableSignOff = (state: RootState) =>
  state.profile.enableSignOff;

export const selectLanguage = (state: RootState) => state.profile.language;

export const selectSignOff = (state: RootState) => state.profile.signOff;
