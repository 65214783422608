import MobileStepper from "@mui/material/MobileStepper";
import styled from "styled-components";

const StyledMobileStepper = styled(MobileStepper)`
  && {
    flex: 1;
    padding: 0;
    /* Customize progress color */
    & .MuiMobileStepper-progress {
      width: 100%;
      background-color: #d9d9d9; /* Change the progress color here */
    }

    /* Customize the bar color (for Linear progress) */
    & .MuiLinearProgress-bar {
      background-color: #37eac2; /* Change the bar color here */
    }
  }
`;

interface Props {
  activeStep: number;
  steps: number;
}

export const Stepper = ({ activeStep, steps, ...rest }: Props) => {
  return (
    <StyledMobileStepper
      variant="progress"
      steps={steps}
      position="static"
      activeStep={activeStep}
      nextButton={null}
      backButton={null}
      {...rest}
    />
  );
};
