import styled from "styled-components";
import { theme } from "themes";

const Item = styled.div`
  display: flex;
  flex-direction: row;
  font-family: ${theme.fontFamily.primary};
  align-items: flex-end;
  margin-bottom: 20px;
  margin-top: 20px;
`;

const ItemLabel = styled.span`
  margin-right: 8px;
  font-size: 14px;
  font-family: "Inter";
  line-height: 16px;
  font-family: ${theme.fontFamily.primary};
  cursor: default;
  color: ${theme.colors.black};
`;

const ItemValue = styled.span`
  font-size: 14px;
  font-family: ${theme.fontFamily.primary};
  line-height: 16px;
  color: ${theme.colors.black};
  @media (max-width: 768px) {
    white-space: wrap;
  }
`;

export { Item, ItemLabel, ItemValue };
