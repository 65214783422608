import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "store";
import {
  Signout,
  Signin,
  OnBoarding,
  Settings,
  Payment,
  PaymentFinished,
} from "../scenes";
import {
  useSelectIsLoading,
  useSelectOnboardingComplete,
  useSelectPaymentStatus,
} from "modules/onboarding";
import {
  requestGetOnboardingComplete,
  requestPaymentStatus,
} from "modules/profile";
import { Routes, Route, Navigate } from "react-router-dom";

export const AuthenticatedRoutes = () => {
  const dispatch: AppDispatch = useDispatch();
  const isLoading = useSelectIsLoading();
  const onBoardingComplete = useSelectOnboardingComplete();
  const paymentStatus = useSelectPaymentStatus();
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([
        dispatch(requestGetOnboardingComplete()),
        dispatch(requestPaymentStatus()),
      ]);
      setIsInitialLoad(false); // Set to false after data is fetched
    };
    if (isInitialLoad) {
      fetchData();
    }
  }, [dispatch, isInitialLoad]);

  const isPending = isLoading || isInitialLoad;

  if (isPending) {
    return null;
  }

  if (!onBoardingComplete) {
    return (
      <Routes>
        <Route path="*" element={<Navigate replace to="/onboarding" />} />
        <Route path="/onboarding" element={<OnBoarding />} />
        <Route path="/signin" element={<Signin />} />
        <Route path="/signout" element={<Signout />} />
      </Routes>
    );
  }

  if (paymentStatus !== "trialing" && paymentStatus !== "active") {
    return (
      <Routes>
        <Route path="*" element={<Navigate replace to="/payment" />} />
        <Route path="/payment" element={<Payment />} />
        <Route path="/payment-finished" element={<PaymentFinished />} />
        <Route path="/signin" element={<Signin />} />
        <Route path="/signout" element={<Signout />} />
      </Routes>
    );
  }

  return (
    <Routes>
      <Route path="/" element={<Navigate replace to="/settings" />} />
      <Route path="/onboarding" element={<Navigate replace to="/settings" />} />
      <Route
        path="/onboarding-finished"
        element={<Navigate replace to="/settings" />}
      />
      <Route path="/payment" element={<Payment />} />
      <Route path="/payment-finished" element={<PaymentFinished />} />
      <Route path="/settings/*" element={<Settings />} />
      <Route path="/signin" element={<Signin />} />
      <Route path="/signout" element={<Signout />} />
    </Routes>
  );
};
