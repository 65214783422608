import { AxiosResponse } from "axios";
import axios from "middlewear/axiosconfig";
import { REL_AUTH_URL } from "env-urls";

const signIn = async ({
  consent,
  redirectUrl,
  captchaToken,
}: {
  consent: boolean;
  redirectUrl: string;
  captchaToken: string;
}): Promise<{ authUrl: string }> => {
  const data = {
    redirectUrl,
    consent,
    captchaToken,
  };
  const response = await axios.post(`${REL_AUTH_URL}/authenticate`, data);
  return response.data;
};

const validateCatpcha = async ({
  captchaToken,
}: {
  captchaToken: string;
}): Promise<{ success: string }> => {
  const response = await axios.post(`${REL_AUTH_URL}/validate-captcha`, {
    captchaToken,
  });
  return response.data;
};

const signOut = async (): Promise<AxiosResponse> => {
  return await axios.get(`${REL_AUTH_URL}/request-logout`, {
    withCredentials: true,
  });
};

const getIsAuthenticated = async (): Promise<boolean> => {
  try {
    const response = await axios.get(`${REL_AUTH_URL}/get-is-authenticated`);
    return response.data;
  } catch (err) {
    return false;
  }
};

export const authenticationApi = {
  validateCatpcha,
  signOut,
  signIn,
  getIsAuthenticated,
};
