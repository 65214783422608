import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./initial-state";
import { requestUpdateOnboarding } from "./actions";

import {
  requestGetOnboardingComplete,
  requestPaymentStatus,
} from "modules/profile";

const onboardingSlice = createSlice({
  name: "authenticationSlice",
  initialState: initialState,
  reducers: {
    setOnboardingStep(state, action) {
      state.step = action.payload;
    },
    updateOnboarding(state, action) {
      return { ...state, ...action.payload }; // Return a new state object with updated values
    },
  },
  extraReducers(builder) {
    builder.addCase(requestUpdateOnboarding.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(requestUpdateOnboarding.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(requestUpdateOnboarding.fulfilled, (state) => {
      return {
        ...initialState,
        paymentStatus: state.paymentStatus,
        onBoardingComplete: true,
      };
    });
    builder.addCase(requestPaymentStatus.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(requestPaymentStatus.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(requestPaymentStatus.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload) state.paymentStatus = action.payload;
      else state.paymentStatus = "";
    });
    builder.addCase(requestGetOnboardingComplete.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(requestGetOnboardingComplete.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(
      requestGetOnboardingComplete.fulfilled,
      (state, { payload }) => {
        state.onBoardingComplete = payload;
        state.isLoading = false;
      }
    );
  },
});

export const { setOnboardingStep, updateOnboarding } = onboardingSlice.actions;

export const onboardingReducer = onboardingSlice.reducer;
