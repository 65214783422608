import styled from "styled-components";
import { theme } from "themes";

interface Props {
  color?: string;
}

const Container = styled.div<Props>`
  margin-top: 20px;
  margin-bottom: 20px;
  border-top: ${(props) =>
    props.color
      ? `solid 1px ${props.color}`
      : `solid 1px ${theme.colors.lightGray}`};
`;

export const SideBarDivider = ({ color }: Props) => {
  return <Container color={color} />;
};
