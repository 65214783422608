import axios from "middlewear/axiosconfig";
import { REL_PROFILE_URL, PAYMENT_REDIRECT_URL } from "env-urls";

const getProfile = async () => {
  const response = await axios.get(`${REL_PROFILE_URL}/get-profile-details`);
  return response.data;
};

const updateProfile = async (update: any) => {
  const response = await axios.patch(
    `${REL_PROFILE_URL}/update-profile-details`,
    update
  );
  return response.data;
};

const getOnboardingComplete = async () => {
  const response = await axios.get(
    `${REL_PROFILE_URL}/get-onboarding-complete`
  );
  return response.data;
};

const getProfileEmail = async () => {
  const response = await axios.get(`${REL_PROFILE_URL}/get-profile-email`);
  return response.data;
};

const getCheckoutSessions = async (): Promise<{
  sessionId: string;
  redirectUrl?: string;
}> => {
  const params = {
    redirectUrl: PAYMENT_REDIRECT_URL,
  };
  const response = await axios.get(
    `${REL_PROFILE_URL}/request-checkout-session`,
    { params }
  );
  return response.data;
};

const getPaymentPlan = async (): Promise<{ paymentPlans: string }> => {
  const response = await axios.get(
    `${REL_PROFILE_URL}/request-get-payment-plan`
  );
  return response.data;
};

const getPaymentStatus = async (): Promise<string | null> => {
  const response = await axios.get(`${REL_PROFILE_URL}/request-has-paid`);
  return response.data;
};

export const profileApi = {
  getProfile,
  updateProfile,
  getOnboardingComplete,
  getProfileEmail,
  getCheckoutSessions,
  getPaymentPlan,
  getPaymentStatus,
};
