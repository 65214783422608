import { createAsyncThunk } from "@reduxjs/toolkit";
import { selectRedirectUrl, selectIsConsent } from "./selectors";
import { authenticationApi } from "./api";
import { RootState } from "reducers";
import { SETTINGS_URL, HOME_URL } from "../../env-urls";
import {
  AmplitudeEvents,
  SendEventProps,
} from "../../hooks/useRudderAnalytics";

export const requestLogout = createAsyncThunk(
  "authentication/request-logout",
  async (_, { rejectWithValue }) => {
    try {
      const redirectUrl = HOME_URL;
      await authenticationApi.signOut();
      return redirectUrl;
    } catch (ex) {
      console.error(ex, "emails/request-emails");
      rejectWithValue(ex);
    }
  }
);

export const requestLogin = createAsyncThunk(
  "authentication/request-login",
  async (
    args: {
      captchaToken: string;
      consentValue?: boolean;
      sendRudderstackEvent: (props: SendEventProps) => void;
    },
    { rejectWithValue, getState }
  ) => {
    try {
      const { consentValue, sendRudderstackEvent, captchaToken } = args;
      const state = getState() as RootState;
      const redirectUrl = selectRedirectUrl(state).trim();
      const consent =
        consentValue || selectIsConsent(state);
      const createdRedirectUrl =
        redirectUrl.length > 0 ? redirectUrl : SETTINGS_URL;

      const response = await authenticationApi.signIn({
        redirectUrl: createdRedirectUrl,
        consent,
        captchaToken,
      });
      const authUrl = response?.authUrl || "";
      sendRudderstackEvent({
        eventName: AmplitudeEvents.ON_LOGIN_BUTTON_CLICKED,
      });
      return authUrl;
    } catch (ex) {
      console.error(ex, "emails/requestLogin");
      rejectWithValue(ex);
    }
  }
);

export const requestAuthenticateCaptcha = createAsyncThunk(
  "authentication/request-authenticate-captcha",
  async (captchaToken: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await authenticationApi.validateCatpcha({
        captchaToken: captchaToken,
      });

      if (!response.success) {
        dispatch(requestLogout());
      }

      return response;
    } catch (ex) {
      console.error(ex, "authentication/requestAuthenticateCaptcha");
      return rejectWithValue(ex); // Ensure rejectWithValue is returned
    }
  }
);

export const requestIsAuthenticated = createAsyncThunk(
  "authentication/request-is-authenticated",
  async (_, { rejectWithValue }) => {
    try {
      return await authenticationApi.getIsAuthenticated();
    } catch (ex) {
      console.error(ex, "emails/requestIsAuthenticated");
      rejectWithValue(ex);
    }
  }
);
