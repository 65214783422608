import styled from "styled-components";
import { Image } from "components";
import { theme } from "themes";

const Container = styled.div<{
  active?: boolean;
  disable?: boolean;
  height?: string;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  opacity: ${(props) => (props.active ? 1 : props.disable ? "0.5" : "0.7")};
  cursor: ${(props) => (props.disable ? "not-allowed" : "pointer")};
  justify-content: flex-start;
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 15px;
  &:hover {
    opacity: ${(props) => (props.active ? 1 : props.disable ? "0.5" : "0.80")};
  }
`;

const Item = styled.div`
  color: ${theme.colors.black};
  font-size: 14px;
  font-family: Inter;
  line-height: 16px;
  margin-left: 30px;
  white-space: nowrap;
`;

interface Props {
  title?: string;
  iconName?: string;
  active?: boolean;
  onClick?: () => void;
  disable?: boolean;
}

export const SideBarItem = ({
  title,
  onClick,
  disable,
  iconName,
  active,
}: Props) => {
  const _onClick = () => {
    if (!disable && onClick) {
      onClick();
    }
  };

  return (
    <Container disable={disable} active={active} onClick={_onClick}>
      {iconName && <Image style={{ width: "25px" }} fileName={iconName} />}
      {title && <Item>{title}</Item>}
    </Container>
  );
};
