import { Signin } from "../scenes";
import { Routes, Route, Navigate } from "react-router-dom";

export const UnauthenticatedRoutes = () => {
  return (
    <Routes>
      <Route path="*" element={<Navigate replace to="/signin" />} />
      <Route path="/signin" element={<Signin />} />
    </Routes>
  );
};
