import { combineReducers } from "@reduxjs/toolkit";
import { emailReducer } from "./modules/email";
import { authenticationReducer } from "./modules/authentication";
import { profileReducer } from "./modules/profile";
import { onboardingReducer } from "./modules/onboarding";

export enum REDUCER_KEYS {
  emails = "emails",
  authentication = "authentication",
  profile = "profile",
  onboarding = "onboarding",
}

export const rootReducer = combineReducers({
  emails: emailReducer,
  authentication: authenticationReducer,
  profile: profileReducer,
  onboarding: onboardingReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
