import { RootState } from "reducers";

export const selectRedirectUrl = (state: RootState) =>
  state.authentication.redirectUrl;

export const selectIsConsent = (state: RootState) =>
  state.authentication.consent;

export const selectIsAuthentication = (state: RootState) =>
  state.authentication.authenticated;

export const selectAllowedCode = (state: RootState) =>
  state.authentication.allowedCode;

export const selectLastVisitedPage = (state: RootState) =>
  state.authentication.lastVisitedPage;

export const selectAllAuthentication = (state: RootState) =>
  state.authentication;

export const selectIsLoading = (state: RootState) =>
  state.authentication.isLoading;

export const selectReferrer = (state: RootState) =>
  state.authentication.referrer;
