import axios from "axios";
import store from "../store";
import {
  setRedirectUrl,
  setAuthentication,
  setConsent,
} from "modules/authentication/slice";
import { BASE_URL } from "env-urls";
import { ErrorReason } from "./constants";

const instance = axios.create({
  baseURL: `${BASE_URL}/`, // Your API base URL
  timeout: 30000, // Request timeout in milliseconds
  withCredentials: true, // Important for handling cookies and authentication
});

// Add a response interceptor to handle 401 errors
instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    console.log(error, "error");
    if (error.response.status === 401) {
      // Redirect the user to the login page
      const errorReason = error.response.data.reason;
      if (errorReason === ErrorReason.ConsentIsNeeded) {
        store.dispatch(setConsent(true));
      }

      const currentURL = window.location.href;
      const authenticated = store.getState().authentication.authenticated;
      if (error.response)
        if (authenticated) {
          store.dispatch(setRedirectUrl(currentURL));
        }
      store.dispatch(setAuthentication(false));
    }
    return Promise.reject(error);
  }
);

export default instance;
