import styled from "styled-components";
import { isExtension } from "../../utils";
import { Typography } from "@mui/material";
import MaterialBox from "@mui/material/Box";
import { theme } from "themes";

const ScreenWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  ${isExtension() &&
  `
    width: 250px;
    max-width: none;
    background: ${theme.colors.blue}
  `}
`;

const List = styled.div`
  width: 100%;
  overflow-y: scroll;
  ${isExtension() &&
  `
    max-height: 400px;
  `}
`;

const Container = styled.div`
  background-color: ${theme.colors.blue};
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow-y: auto;
`;

const ActionsWrapper = styled.div`
  flex: 1 1 0%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 40px;
`;

const Header = styled(Typography)`
  && {
    color: ${theme.colors.black};
    font-size: 48px;
    font-weight: 900;
    cursor: default;
    font-family: ${theme.fontFamily.secondary};
  }
`;

const SubHeader = styled(Typography)`
  && {
    color: ${theme.colors.black};
    font-size: 14px;
    cursor: default;
    font-family: ${theme.fontFamily.primary};
    white-space: wrap;
  }
`;

const Description = styled(Typography)`
  && {
    color: ${theme.colors.black};
    font-size: 12px;
    font-weight: 500;
    font-family: ${theme.fontFamily.primary};
    cursor: default;
    white-space: wrap;
  }
`;

const Box = styled(MaterialBox)``;

const SkipButton = styled.div`
  align-self: flex-end;
  color: #5d5d5b;
  cursor: pointer;
  height: 30px;
  font-family: ${theme.fontFamily.primary};
  display: flex;
  align-items: flex-end;
`;

const StepperContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export {
  Container,
  Header,
  ActionsWrapper,
  SubHeader,
  Description,
  SkipButton,
  StepperContainer,
  ScreenWrapper,
  List,
  Box,
};
