import UIButton, { ButtonProps as UIButtonProps } from "@mui/material/Button";
import styled, { css } from "styled-components";
import { Image, ImageProps } from "components";
import { theme } from "themes";
import { useState } from "react";

interface StyledComponentProps {
  width?: number; // Define the 'width' prop here
  height?: number; // Define the 'width' prop here
}

export interface ButtonProps extends UIButtonProps, StyledComponentProps {}

export const Button = ({ ...props }: ButtonProps) => {
  return <UIButton {...props} />;
};

// Define the styled components
const defaultStyle = css<StyledComponentProps>`
  padding: 0px 15px;
  border-radius: 12px;
  font-size: 16px;
  text-transform: capitalize;
  font-family: ${theme.fontFamily.primary};
  font-weight: 700;
  outline: none;
  ${(props) => `height: ` + (props.height ? `${props.height}px;` : `48px;`)}
  ${(props) => (props.width ? `width: ${props.width}px;` : "")}
`;

const PrimaryButton = styled((props: ButtonProps) => <Button {...props} />)`
  && {
    ${defaultStyle}
    background-color: ${theme.colors.blue};

    color: #ffffff;
    &:hover {
      background-color: ${theme.colors.darkBlue};
    }
  }
`;

const SecondaryButton = styled((props: ButtonProps) => <Button {...props} />)`
  && {
    ${defaultStyle}
    background-color: ${theme.colors.white};
    border: solid 1px ${theme.colors.black};
    color: ${theme.colors.black};
    &:hover {
      border-color: ${theme.colors.blue};
      background-color: ${theme.colors.white};
      color: ${theme.colors.blue};
    }
  }
`;

interface IconButtonProps extends ButtonProps, ImageProps {
  fileActiveName?: string;
  bgColor?: string;
  activebgColor?: string;
}

const StyledIconButton = styled.div<IconButtonProps>`
  background-color: ${(props) => props.bgColor};
  cursor: pointer;
  border-radius: 30px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${(props) => `height: ` + (props.height ? `${props.height}px;` : `25px;`)}
  ${(props) => (props.width ? `width: ${props.width}px;` : "")}
  &:hover {
    background-color: ${(props) =>
      props.activebgColor || theme.colors.lightGray};
  }
`;

const IconButton = ({
  fileName,
  fileActiveName,
  ...props
}: IconButtonProps) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <StyledIconButton
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      {...(props as any)} // Props spreading for additional attributes
    >
      <Image
        style={{ height: "100%" }}
        fileName={isHovered && fileActiveName ? fileActiveName : fileName}
      ></Image>
    </StyledIconButton>
  );
};

const PrimaryTransparentButton = styled((props: ButtonProps) => (
  <Button {...props} />
))`
  && {
    ${defaultStyle}
  }
`;

export { PrimaryButton, SecondaryButton, PrimaryTransparentButton, IconButton };
