import { useSelector } from "react-redux";
import {
  selectProfileInformation,
  selectProfileUseDetails,
  selectCompanyDescription,
  selectRole,
  selectProductDetails,
  selectPoliciesFaq,
  selectToneStyle,
  selectSendlogo,
  selectEnableSignOff,
  selectSignOff,
  selectEmail,
  selectName,
  selectPaymentPlans,
  selectPaymentId,
  selectLanguage,
  selectTokenLimit,
} from "./selectors";

export const useSelectProfileInformation = (): any =>
  useSelector(selectProfileInformation);

export const useSelectProfileUseDetails = (): string | undefined =>
  useSelector(selectProfileUseDetails);

export const useSelectAICustomizations = () => {
  return {
    signOff: useSelector(selectSignOff),
    companyDescription: useSelector(selectCompanyDescription),
    currentRole: useSelector(selectRole),
    productDetails: useSelector(selectProductDetails),
    policiesFaq: useSelector(selectPoliciesFaq),
    toneStyle: useSelector(selectToneStyle),
    sendLogo: useSelector(selectSendlogo),
    enableSignOff: useSelector(selectEnableSignOff),
    language: useSelector(selectLanguage),
  };
};

export const useSelectPaymentPlans = (): string =>
  useSelector(selectPaymentPlans);

export const useSelectProfileDetails = () => {
  return {
    name: useSelector(selectName),
    role: useSelector(selectRole),
    email: useSelector(selectEmail),
    tokenLimit: useSelector(selectTokenLimit),
  };
};

export const useSelectPaymentId = (): string | undefined =>
  useSelector(selectPaymentId);
