import { Referrer } from "./types";

export interface INTIAL_STATE {
  redirectUrl: string;
  authenticated: boolean;
  isLoading?: boolean;
  error?: string;
  allowedCode: string;
  lastVisitedPage?: string;
  consent: boolean;
  referrer: Referrer;
}

export const initialState: INTIAL_STATE = {
  authenticated: false,
  allowedCode: "",
  redirectUrl: "",
  consent: true,
  referrer: {},
};
