import { useSelector } from "react-redux";
import { INTIAL_STATE } from "modules/authentication/initial-state";
import {
  selectRedirectUrl,
  selectIsAuthentication,
  selectAllowedCode,
  selectLastVisitedPage,
  selectAllAuthentication,
  selectIsLoading,
  selectReferrer,
} from "./selectors";
import { Referrer } from "./types";

export const useSelectRedirectUrl = (): string | undefined =>
  useSelector(selectRedirectUrl);

export const useSelectIsAuthentication = (): boolean =>
  useSelector(selectIsAuthentication);

export const useSelectAllowedCode = (): string =>
  useSelector(selectAllowedCode);

export const useSelectLastVisitedPage = (): string | undefined =>
  useSelector(selectLastVisitedPage);

export const useSelectAllAuthentication = (): INTIAL_STATE =>
  useSelector(selectAllAuthentication);

export const useSelectIsLoading = (): boolean | undefined =>
  useSelector(selectIsLoading);

export const useSelectReferrer = (): Referrer | undefined =>
  useSelector(selectReferrer);
