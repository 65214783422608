import { useEffect, useRef, useState } from "react";
import { Box } from "components";
import { useDispatch } from "react-redux";
import { AppDispatch } from "store";
import {
  useSelectAICustomizations,
  useSelectProfileDetails,
  updateProfile,
  requestGetProfile,
  requestGetTokenLimit,
} from "modules/profile";

import { Header } from "./components";
import {
  CurrentRole,
  CommunicateStyle,
  LanguageSelection,
  FormTextArea,
} from "../onboarding/components";

import { GeneralProps } from "./types";
import { SignOff } from "./components/signOff";

interface Props extends GeneralProps {}

const characterCounter = ({
  companyDescription,
  productDetails,
  policiesFaq,
}: {
  companyDescription: string;
  productDetails: string;
  policiesFaq: string;
}) => {
  const totalCharacters =
    companyDescription.length + productDetails.length + policiesFaq.length;
  return totalCharacters;
};

const calculateRemainingCharacters = ({
  totalCharacterCount,
  tokenLimit,
}: {
  totalCharacterCount: number;
  tokenLimit?: number;
}) => {
  if (!tokenLimit) return 0;
  const characterLimit = tokenLimit * 3.9;
  const remainingCharacters = characterLimit - totalCharacterCount;
  return remainingCharacters;
};

export const AICustomizations = ({ onChange }: Props) => {
  const dispatch: AppDispatch = useDispatch();
  const characterLimitErrorRef: any = useRef(null);
  const [remainingCharacters, setRemainingCharacters] = useState<
    number | undefined
  >();

  const {
    companyDescription,
    currentRole,
    productDetails,
    policiesFaq,
    toneStyle,
    signOff,
    enableSignOff,
    language,
  } = useSelectAICustomizations();

  const { name, tokenLimit } = useSelectProfileDetails();

  const validateNumberTokens = (): [boolean, string] => {
    if (remainingCharacters && remainingCharacters <= 0) {
      return [false, "You have exceeded the character limit"];
    }
    return [true, ""];
  };

  const _onChange = (value: string | boolean, type: string) => {
    // Skip non-string values (e.g., boolean for toggles)
    if (typeof value !== "string") {
      const action = () => dispatch(updateProfile({ [type]: value }));
      onChange(action);
      return;
    }

    // Calculate the potential new total character count after this update
    let newValue = value;
    const newTotalCharacterCount = characterCounter({
      ...{
        companyDescription,
        productDetails,
        policiesFaq,
      }, // Current state
      [type]: newValue, // Update with the new value
    });

    // Calculate remaining characters with the potential new total
    let newRemainingCharacters = calculateRemainingCharacters({
      totalCharacterCount: newTotalCharacterCount,
      tokenLimit,
    });

    // Check if the new value exceeds the character limit and if we're not already at 0 remaining characters
    if (
      newRemainingCharacters < 0 &&
      remainingCharacters !== undefined &&
      remainingCharacters > 0
    ) {
      const excessCharacters = Math.abs(newRemainingCharacters);
      // Calculate the max length allowed based on the excess characters
      const maxLength = newValue.length - excessCharacters;
      // Trim the newValue to fit within the character limit
      newValue = newValue.slice(0, maxLength);
      // Recalculate the new remaining characters after trimming
      newRemainingCharacters = calculateRemainingCharacters({
        totalCharacterCount: characterCounter({
          ...{
            companyDescription,
            currentRole,
            productDetails,
            policiesFaq,
            toneStyle,
            signOff,
          },
          [type]: newValue,
        }),
        tokenLimit,
      });
    }

    // Condition to prevent trimming when already at 0 remaining characters
    if (
      remainingCharacters !== undefined &&
      remainingCharacters <= 0 &&
      newRemainingCharacters < 0
    ) {
      // In this case, do not allow the update to proceed if it would further reduce the remaining character count
      // Optionally, you might want to alert the user or handle this case differently.
      console.log("Character limit reached. Cannot add more characters.");
      return; // Stop the update
    }

    setRemainingCharacters(newRemainingCharacters);
    const action = () => dispatch(updateProfile({ [type]: newValue }));
    onChange(action);
  };

  useEffect(() => {
    dispatch(requestGetProfile());
    dispatch(requestGetTokenLimit());
  }, [dispatch]);

  const mediaQuery = window.matchMedia("(max-width: 768px)");

  return (
    <>
      <Header
        title={`AI Customisations`}
        subtitle="This information is employed by the AI email assistant to produce
        replies that are aware of the business."
      />

      <Box maxWidth={450} marginTop="5px">
        <FormTextArea
          title={"Let's start with a brief description of your company"}
          textAreaPlaceholder={
            "E.g. GiveMeTap manufactures eco-friendly water bottles"
          }
          onChangeTextArea={(value) => _onChange(value, "companyDescription")}
          textAreaValue={companyDescription}
          charactersLeft={
            remainingCharacters !== undefined && remainingCharacters < 1000
              ? remainingCharacters
              : undefined
          }
          onValidate={validateNumberTokens}
          errorMessageRef={characterLimitErrorRef}
        />

        <Box marginTop="30px">
          <CurrentRole
            value={currentRole}
            onChange={(value) => _onChange(value, "currentRole")}
          />
        </Box>
        <Box marginTop="30px">
          <FormTextArea
            onChangeTextArea={(value) => _onChange(value, "productDetails")}
            title={"What are you company products and pricing"}
            textAreaPlaceholder={
              mediaQuery.matches
                ? "Bottle size & styling:\n300-499ml 500ml-999ml 1,000ml-3,000ml\n\n"
                : "Bottle size & styling:\n300-499ml 500ml-999ml 1,000ml-3,000ml\n\nPricing:\n500ml shorter Insulated Bottle  £18.60 £15.90 £13.50 £12.30 £11.40\n500ml Insulated Bottle  £18.00 £15.40 £13.10 £11.90 £11.10\n700ml Insulated Bottle  £19.90 £17.00 £14.50 £13.10 £12.20"
            }
            textAreaValue={productDetails}
            charactersLeft={
              remainingCharacters !== undefined && remainingCharacters < 1000
                ? remainingCharacters
                : undefined
            }
            onValidate={validateNumberTokens}
            errorMessageRef={characterLimitErrorRef}
          />
        </Box>
        <Box marginTop="30px">
          <FormTextArea
            onChangeTextArea={(value) => _onChange(value, "policiesFaq")}
            title={"What are your FAQ or customer service policies?"}
            textAreaPlaceholder={
              "E.g. We offer a 30-day return policy on unused items."
            }
            textAreaValue={policiesFaq}
            charactersLeft={
              remainingCharacters !== undefined && remainingCharacters < 1000
                ? remainingCharacters
                : undefined
            }
            onValidate={validateNumberTokens}
            errorMessageRef={characterLimitErrorRef}
          />
        </Box>
        <Box marginTop="30px">
          <CommunicateStyle
            value={toneStyle}
            onChange={(value) => _onChange(value, "toneStyle")}
          />
        </Box>
        <Box marginTop="30px">
          <LanguageSelection
            value={language}
            onChange={(value) => _onChange(value, "language")}
          />
        </Box>
        <Box marginTop="30px">
          <SignOff
            name={name}
            signOffText={signOff}
            checked={enableSignOff}
            onChangeSignOff={(value) => _onChange(value, "signOff")}
            onChangeEnableSignOff={() =>
              _onChange(!enableSignOff, "enableSignOff")
            }
          />
        </Box>
      </Box>
    </>
  );
};
