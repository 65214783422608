import { theme } from "themes";
import { Header, Item, ItemLabel, ItemValue } from "./components";

export const Support = () => {
  const email = "support@clevermail.co";

  const mediaQuery = window.matchMedia("(max-width: 768px)"); // Adjust the max-width as needed

  return (
    <>
      <Header
        title={`Support`}
        subtitle="Contact us if you have any questions or concerns"
      />
      <Item>
        {!mediaQuery && <ItemLabel>{`Email:`}</ItemLabel>}
        <ItemValue>
          <a
            style={{ textDecoration: "underline", color: theme.colors.black }}
            href={`mailto:${email}`}
          >
            {email}
          </a>
        </ItemValue>
      </Item>
    </>
  );
};
