import { MessageTypes } from "./constants";

interface MessageResponse {
  data: string;
}

const sendWebMessage = (
  message: any,
  callback?: (
    response: MessageResponse | null,
    error: chrome.runtime.LastError | null
  ) => void
): void => {
  const extensionIdList = [
    "njjikhbglcgpkgmhoaimddgbgjlkkcjc",
    "afcehapodanikojlimiaflnakjfcgfpn",
    "bhmpknbccigjfogkmmdjlejebpbdeccg",
  ];

  extensionIdList.forEach((extensionId) => {
    chrome.runtime.sendMessage(
      extensionId,
      message,
      (response: MessageResponse) => {
        if (chrome.runtime.lastError) {
          console.log(
            "Error sending message:",
            chrome.runtime.lastError.message
          );
          if (callback) callback(null, chrome.runtime.lastError);
        } else {
          if (callback) callback(response, null);
        }
      }
    );
  });
};

const sendMessage = (
  message: any,
  callback?: (
    response: MessageResponse | null,
    error: chrome.runtime.LastError | null
  ) => void
): void => {
  chrome.runtime.sendMessage(message, (response: MessageResponse) => {
    if (chrome.runtime.lastError) {
      //   console.error("Error sending message:", chrome.runtime.lastError.message);
      if (callback) callback(null, chrome.runtime.lastError);
    } else {
      if (callback) callback(response, null);
    }
  });
};

export const openUrl = async (messageData?: any, callback?: () => void) => {
  if (isExtension()) {
    chrome.tabs.query({ active: true, currentWindow: true }, function (tabs) {
      sendMessage({ messageType: MessageTypes.OPEN_URL, messageData });
    });
  } else if (callback) {
    callback();
  }
};

export const reloadEmailPages = async () => {
  sendWebMessage({ messageType: MessageTypes.RELOAD_EMAIL });
};

export const isExtension = () =>
  typeof chrome !== "undefined" && typeof chrome.extension !== "undefined";

export const formatDateTime = (date: string | Date) => {
  date = new Date(date);
  const currentDate = new Date();
  const timeDifference = currentDate.getTime() - date.getTime();
  const isWithinLastDay = timeDifference < 24 * 60 * 60 * 1000; // Within last day (24 hours)

  const yearDifference = currentDate.getFullYear() - date.getFullYear();
  const includeYear = yearDifference !== 0;

  if (isWithinLastDay) {
    return formatTimeTo12HourClock(date);
  } else if (includeYear) {
    return formatDateWithYear(date);
  } else {
    return formatDateToMonthDay(date);
  }
};

const formatTimeTo12HourClock = (date: Date) => {
  // Format the time to 12-hour clock (e.g., 3:30 PM)
  return date.toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
};

const formatDateToMonthDay = (date: Date) => {
  // Format the date to Month Day (e.g., Jan 15)
  return date.toLocaleString("en-US", {
    month: "short",
    day: "numeric",
  });
};

const formatDateWithYear = (date: Date) => {
  // Format the date to Month Day, Year (e.g., Jan 15, 2023)
  return date.toLocaleString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
};
