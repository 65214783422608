import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "reducers";
import { selectOnboardingInformation } from "./selectors";
import { profileApi } from "modules/profile/api";
import {
  AmplitudeEvents,
  SendEventProps,
} from "../../hooks/useRudderAnalytics";

export const requestUpdateOnboarding = createAsyncThunk(
  "onboarding/request-update-onboarding",
  async (
    args: {
      sendRudderstackEvent: (props: SendEventProps) => void;
    },
    { getState, rejectWithValue }
  ) => {
    try {
      const { sendRudderstackEvent } = args;
      const state = getState();
      const onboarding = selectOnboardingInformation(state as RootState);
      const sendObj: any = { ...onboarding, onBoardingComplete: true };
      sendRudderstackEvent({
        eventName: AmplitudeEvents.ON_ONBOARDING_COMPLETED,
      });
      return await profileApi.updateProfile(sendObj);
    } catch (ex) {
      console.error(ex, "onboarding/requestUpdateOnboarding");
      rejectWithValue(ex);
    }
  }
);
