import React, { useState } from "react";
import styled from "styled-components";
import { Image } from "components";
import { theme } from "themes";

// Styled container that adjusts child Link styles on hover
const LinkContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  font-family: ${theme.fontFamily.primary};
  color: ${theme.colors.black};
  &:hover .styled-link {
    color: ${theme.colors.blue};
    text-decoration: underline;
  }
`;

const StyledLink = styled.div.attrs({
  className: "styled-link",
})``;

interface Props {
  href: string;
  fileName?: string;
  activeFileName?: string;
  children: any;
}

export const Link: React.FC<Props> = ({
  href,
  fileName,
  activeFileName,
  children,
}) => {
  // Using useState to manage hover state
  const [isHovered, setIsHovered] = useState(false);

  const goToLink = () => window.open(href, "_blank");

  return (
    <LinkContainer
      onClick={goToLink}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {fileName && activeFileName && (
        <Image
          style={{
            width: "25px",
            marginRight: "35px",
            opacity: isHovered ? "1" : "0.7", // Apply dynamic opacity based on hover
          }}
          fileName={isHovered ? activeFileName : fileName}
          alt=""
        />
      )}
      <StyledLink
        style={{
          color: isHovered ? `${theme.colors.blue}` : "initial",
          opacity: isHovered ? "1" : "0.7",
        }}
      >
        {children}
      </StyledLink>
    </LinkContainer>
  );
};
