import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./initial-state";
import {
  requestEmails,
  requestEmail,
  requestGenerateEmailReply,
  requestGenerateComposeEmail,
  requestUpdateThreadArchiveStatus,
  requestComposeEmailReply,
  requestComposeEmail,
  requestForwardEmail,
} from "./actions";
import { emailAdapter } from "./adapter";

const emailSlice = createSlice({
  name: "emailSlice",
  initialState: emailAdapter.getInitialState(initialState),
  reducers: {
    setSearch(state, action) {
      state.search = action.payload;
    },
    setEmailState(state, action) {
      state.emailState = action.payload;
      state.nextPageToken = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(requestEmails.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(requestEmails.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(requestEmails.fulfilled, (state, action) => {
      const payload = action.payload;
      if (payload) {
        const search = state.search;
        const { list, nextPageToken } = payload;
        if (state.nextPageToken === undefined || search.length > 0) {
          emailAdapter.setAll(state as any, list);
        } else {
          emailAdapter.upsertMany(state as any, list);
        }
        state.nextPageToken = nextPageToken;
      }
      state.loading = false;
    });
    builder.addCase(requestEmail.pending, (state) => {
      if (state.selectedEmail) state.selectedEmail.reply = "";
      state.selectedEmail = undefined;
      state.loading = true;
    });
    builder.addCase(requestEmail.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(requestEmail.fulfilled, (state, action) => {
      const payload = action.payload;
      if (payload) {
        state.selectedEmail = payload;
      }
      state.loading = false;
    });
    builder.addCase(requestUpdateThreadArchiveStatus.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(requestUpdateThreadArchiveStatus.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(requestUpdateThreadArchiveStatus.fulfilled, (state) => {
      state.loading = false;
      if (state.selectedEmail)
        state.selectedEmail.archived = !state.selectedEmail.archived;
    });
    builder.addCase(requestComposeEmailReply.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(requestComposeEmailReply.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(requestComposeEmailReply.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(requestComposeEmail.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(requestComposeEmail.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(requestComposeEmail.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(requestForwardEmail.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(requestForwardEmail.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(requestForwardEmail.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(requestGenerateEmailReply.pending, (state, action) => {
      if (state.selectedEmail) state.selectedEmail.reply = "";
      state.emailReplyLoading = true;
    });
    builder.addCase(requestGenerateEmailReply.rejected, (state, action) => {
      const payload = action.payload;
      if (typeof payload === "string") {
        state.error = payload;
      }
      state.emailReplyLoading = false;
    });
    builder.addCase(requestGenerateEmailReply.fulfilled, (state, action) => {
      const payload = action.payload;
      if (!!payload) {
        const { instructions, reply, gptInput } = payload;
        if (state.selectedEmail)
          state.selectedEmail.instructions = instructions;
        if (state.selectedEmail) {
          state.selectedEmail.reply = reply;
          state.selectedEmail.gptInput = gptInput;
        }
      }
      state.emailReplyLoading = false;
    });
    builder.addCase(requestGenerateComposeEmail.pending, (state, action) => {
      if (state.selectedEmail) state.selectedEmail.reply = "";
      state.emailReplyLoading = true;
    });
    builder.addCase(requestGenerateComposeEmail.rejected, (state, action) => {
      const payload = action.payload;
      if (typeof payload === "string") {
        state.error = payload;
      }
      state.emailReplyLoading = false;
    });
    builder.addCase(requestGenerateComposeEmail.fulfilled, (state, action) => {
      const payload = action.payload;
      if (!!payload) {
        const { instructions, reply, gptInput } = payload;
        state.selectedEmail = {} as any;
        if (state.selectedEmail) {
          state.selectedEmail.instructions = instructions;
          state.selectedEmail.reply = reply;
          state.selectedEmail.gptInput = gptInput;
        }
      }
      state.emailReplyLoading = false;
    });
  },
});

export const { setSearch, setEmailState } = emailSlice.actions;

export const emailReducer = emailSlice.reducer;
