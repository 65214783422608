import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { styled } from "styled-components";
import { Image, SecondaryButton, SubHeader } from "components";
import { openUrl } from "utils";
import { APP_BASE_URL } from "env-urls";
import { requestGetProfile, useSelectProfileDetails } from "modules/profile";
import { AppDispatch } from "store";
import { theme } from "themes";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 20px;
`;

const LogoWrapper = styled.div`
  text-align: center;
  padding-right: 30px;
  padding-left: 30px;
`;

const StyledSecondaryButton = styled(SecondaryButton)`
  && {
    margin-bottom: 15px;
    width: 165px;
    height: 40px;
    border: none;
  }
`;

const StyledGmailButton = styled(SecondaryButton)`
  && {
    width: 165px;
    height: 40px;
    border: none;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
  margin-bottom: 10px;
  margin-top: 20px;
`;

const StyledTitle = styled(SubHeader)`
  && {
    font-weight: 400;
    font-size: 14px;
    color: ${theme.colors.white};
    font-family: ${theme.fontFamily.primary};
    text-align: center;
    white-space: nowrap;
    margin-top: 10px;
  }
`;

export const ExtensionDashboard = () => {
  const dispatch: AppDispatch = useDispatch();
  const goToGmail = () => openUrl("https://gmail.com");
  const goToSettings = () => openUrl(APP_BASE_URL);
  let { email } = useSelectProfileDetails();

  if (email) email = email.charAt(0).toUpperCase() + email.slice(1);

  useEffect(() => {
    dispatch(requestGetProfile());
  }, [dispatch]);

  return (
    <>
      <Wrapper>
        <LogoWrapper>
          <Image
            style={{
              width: "100%",
            }}
            fileName="logo-large"
          />
        </LogoWrapper>
        <StyledTitle>{email}</StyledTitle>
        <ButtonWrapper>
          <StyledSecondaryButton onClick={goToSettings}>
            Profile Settings
          </StyledSecondaryButton>
          <StyledGmailButton onClick={goToGmail}>
            Try in Gmail
          </StyledGmailButton>
        </ButtonWrapper>
      </Wrapper>
    </>
  );
};
