import styled from "styled-components";
import { PayListHeader, HeaderProps } from "./paylist-header";
import { PayListItem } from "./paylist-item";
import { Box } from "components";

interface Props extends HeaderProps {
  items?: string[];
  disabled?: boolean;
}

const Wrapper = styled(Box)<{ active?: boolean; disabled?: boolean }>`
  flex: 1;
  display: flex;
  flex-direction: column;
  opacity: ${(props) => (props.disabled ? "0.5" : "1")};
`;

export const PayList = ({ title, items, disabled }: Props) => {
  return (
    <Wrapper disabled={disabled}>
      <PayListHeader title={title} />
      {items &&
        items.map((item, index) => (
          <PayListItem key={item.slice(0, 5) + index} title={item} />
        ))}
    </Wrapper>
  );
};
