const fontFamily = {
  primary: "Inter",
  secondary: "Poppins",
};

const colors = {
  white: "#FFFFFF",
  black: "#191836",
  blue: "#3164f4",
  lightBlue: "#3164f433",
  lightestBlue: "#3164f414",
  darkGray: "#575757",
  gray: "#0000003b",
  lightGray: "#3c404314",
  darkBlue: "#1f56f1",
  red: "red",
};

export const baseTheme = {
  colors,
  fontFamily,
};
