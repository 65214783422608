import { RootState } from "reducers";

export const selectIsLoading = (state: RootState) => state.onboarding.isLoading;

export const selectOnboarding = (state: RootState) => state.onboarding;

export const selectOnboardingInformation = (state: RootState) => {
  const {
    currentRole,
    policiesFaq,
    productDetails,
    language,
    companyDescription,
  } = state.onboarding;
  return {
    currentRole,
    companyDescription,
    policiesFaq,
    productDetails,
    language,
  };
};

export const selectOnboardingComplete = (state: RootState) =>
  state.onboarding.onBoardingComplete;

export const selectPaymentStatus = (state: RootState) =>
  state.onboarding.paymentStatus;
