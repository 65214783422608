export enum MessageTypes {
  OPEN_URL = "OPEN_URL",
  GET_EMAIL_HTML = "GET_EMAIL_HTML",
  INITIALIZE_EMAIL_HOVER_LISTENER = "INITIALIZE_EMAIL_HOVER_LISTENER",
  INITIALIZE_PAGE_COMPOSE_LISTENER = "INITIALIZE_PAGE_COMPOSE_LISTENER",
  INITIALIZE_EMAIL = "INITIALIZE_EMAIL",
  GET_EMAIL_ID = "GET_EMAIL_ID",
  GET_EMAIL_RESPONSES = "GET_EMAIL_RESPONSES",
  GENERATE_REPLY = "GENERATE_REPLY",
  REVISE_REPLY = "REVISE_REPLY",
  COMPOSE_EMAIL = "COMPOSE_EMAIL",
  GET_EMAIL_SUMMARY = "GET_EMAIL_SUMMARY",
  EMAIL_SUMMARY = "EMAIL_SUMMARY",
  POPULATE_REPLIES = "POPULATE_REPLIES",
  SMART_REPLY_CLICKED = "SMART_REPLY_CLICKED",
  POPULATE_REPLY = "POPULATE_REPLY",
  POPULATE_REPLY_DETAILS = "POPULATE_REPLY_DETAILS",
  INITIATE_POPULATE_REPLY = "INITIATE_POPULATE_REPLY",
  UPDATE_TONE = "UPDATE_TONE",
  SET_LOADING = "SET_LOADING",
  SET_SUMMARY_LOADING = "SET_SUMMARY_LOADING",
  SET_ERROR = "SET_ERROR",
  SET_INPUT_ERROR = "SET_INPUT_ERROR",
  EMAIL_LOADED = "EMAIL_LOADED",
  COMPOSE_LOADED = "COMPOSE_LOADED",
  GET_USER_EMAIL = "GET_USER_EMAIL",
  DISCONNECT_SOCKETS = "DISCONNECT_SOCKETS",
  DATA_INTERACTION = "DATA_INTERACTION",
  ON_EXPAND_SUMMARY = "ON_EXPAND_SUMMARY",
  ON_INSERT_TEXT = "ON_INSERT_TEXT",
  ON_FEEDBACK_SET = "ON_FEEDBACK_SET",
  RELOAD_EMAIL = "RELOAD_EMAIL",
}

export enum DataActions {
  SET_DATA = "SET_DATA",
  GET_DATA = "GET_DATA",
  REMOVE_DATA = "REMOVE_DATA",
}

export enum DataKeys {
  SELECTED_EMAIL = "SELECTED_EMAIL",
  SELECTED_EMAIL_ID = "SELECTED_EMAIL_ID",
  INSTRUCTIONS = "INSTRUCTIONS",
  GENERATED_REPLIES = "GENERATED_REPLIES",
  PREVIOUS_INPUT_LIST = "PREVIOUS_INPUT_LIST",
  EMAIL_SUMMARY = "EMAIL_SUMMARY",
  USER_EMAIL = "USER_EMAIL",
  EMAIL_CATEGORY = "EMAIL_CATEGORY",
  USER_TONE = "USER_TONE",
  ERROR = "ERROR",
}

interface ResponseData {
  success: boolean;
  data?: string | string[];
  error?: string;
}

export const getToneOptions = () => [
  // {
  //   label: "Auto Detect Tone",
  //   value: "Detect",
  //   icon: "brain",
  //   iconActive: "brain-active",
  // },
  {
    label: "Neutral Tone",
    value: "Neutral",
    icon: "neutral",
    iconActive: "neutral-active",
  },
  {
    label: "Persuasive Tone",
    value: "Persuasive",
    icon: "real-estate",
    iconActive: "real-estate-active",
  },
  {
    label: "Friendly Tone",
    value: "Friendly",
    icon: "happy",
    iconActive: "happy-active",
  },
  {
    label: "Professional Tone",
    value: "Professional",
    icon: "briefcase",
    iconActive: "briefcase-active",
  },
];

export enum ReplyType {
  GENERATED = "generated",
  COMPOSE = "compose",
  REVISE = "revise",
}

export interface IGeneratedReply {
  id: string;
  value: string;
  dateCreated: number;
  wordCount: number;
  timeSaved: number;
  used?: boolean;
  goodResponse?: boolean;
  instructions?: string;
  type?: ReplyType;
}

export type SendResponseFunction = (response: ResponseData) => void;
