import { useEffect, ChangeEvent } from "react";
import styled from "styled-components";
import { AppDispatch } from "store";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  requestUpdateProfile,
  useSelectProfileInformation,
  updateProfile,
  requestGetProfile,
} from "modules/profile";

import { TextArea } from "components";
import { useAnalytics } from "../../hooks/useRudderAnalytics";

const Wrapper = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

export const Profile = () => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const profileInformation = useSelectProfileInformation();
  const { sendRudderstackEvent } = useAnalytics();

  useEffect(() => {
    dispatch(requestGetProfile());
  }, [dispatch]);

  const onChangeTextArea = (event: ChangeEvent<HTMLTextAreaElement>) => {
    dispatch(
      updateProfile({
        type: "generalUseCase",
        value: event.target.value,
      })
    );
  };

  const onSaveUsecaseInfo = () =>
    dispatch(requestUpdateProfile({ sendRudderstackEvent }));

  return (
    <div style={{ margin: "10px", display: "flex", flexDirection: "column" }}>
      <button
        style={{ marginBottom: 25, alignSelf: "flex-start" }}
        onClick={() => navigate("/dashboard")}
      >
        Dashboard
      </button>
      <Wrapper>
        <div
          style={{
            display: "flex",
            marginBottom: 15,
            alignItems: "center",
          }}
        >
          {profileInformation.picture && (
            <img
              style={{ width: 30, height: 30, marginRight: 10 }}
              src={profileInformation.picture}
              alt="alternative-text"
            />
          )}
          <span> {profileInformation.username} </span>
        </div>
        {profileInformation.email && (
          <div
            style={{
              marginBottom: 15,
            }}
          >
            Email: {profileInformation.email}{" "}
          </div>
        )}
        <div style={{ marginBottom: 15 }}>
          Fill in as much data as possible about your business, and any general
          instructions you may have
        </div>
        <TextArea
          maxRows={12}
          minRows={8}
          onChange={onChangeTextArea}
          value={profileInformation.generalUseCase}
        />
      </Wrapper>
      <button style={{ width: 80, height: 40 }} onClick={onSaveUsecaseInfo}>
        Save
      </button>
    </div>
  );
};
