import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { AppDispatch } from "store";
import {
  requestLogin,
  setAuthentication,
  setReferrer,
} from "modules/authentication";
import { Image } from "components";
import styled from "styled-components";
import { Header, Subheader, Agreement, GoogleSignInButton } from "./components";
import { theme } from "themes";
import { useAnalytics } from "../../hooks/useRudderAnalytics";
import ReCAPTCHA from "react-google-recaptcha";
import { CAPTCHA_KEY_V2 } from "env-urls";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  min-height: initial;

  @media (min-width: 768px) {
    flex-direction: row;
    overflow-y: hidden;
    overflow-x: hidden;
    min-height: 800px;
  }
`;

const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 3rem;
  padding-right: 3rem;
  padding-bottom: 5rem;

  @media (min-width: 768px) {
    padding: 3rem;
    max-width: 370px;
    padding-top: 8rem;
  }
`;

const RightSide = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 5rem;
  padding-bottom: 5rem;
  padding-left: 3rem;
  padding-right: 3rem;
  width: 550px;
`;

const BlueBackground = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${theme.colors.blue};
`;

const ContentWrapper = styled.div`
  word-wrap: break-word;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ButtonWrapper = styled.div`
  padding: 2vw;

  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    padding-top: 12vw;
    padding-bottom: 12vw;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 5%;
  left: 5%;

  @media (max-width: 768px) {
    position: initial;
    margin-bottom: 3rem;
    margin-top: 4rem;
  }
`;

const LogoWrapper = styled.div``;

const WhiteBackground = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${theme.colors.white};
`;

export const Signin = () => {
  const dispatch: AppDispatch = useDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  let consentValue: any = queryParams.get("consent");
  let authValue: any = queryParams.get("auth");
  const referralCode = queryParams.get("referralCode");
  const refSource = queryParams.get("refSource");
  const [captchaToken, setCaptchaToken] = useState("");

  const { sendRudderstackEvent } = useAnalytics();

  consentValue =
    consentValue !== null ? consentValue.toLowerCase() === "true" : false;

  authValue = authValue !== null ? authValue.toLowerCase() === "true" : false;

  useEffect(() => {
    if (!authValue) {
      dispatch(setAuthentication(false));
    }
  }, [authValue, dispatch]);

  useEffect(() => {
    if (referralCode) {
      dispatch(setReferrer({ referralCode, refSource }));
    }
  }, [referralCode, refSource, dispatch]);

  const handleCaptchaChange = (value: string | null) => {
    if (value) setCaptchaToken(value);
  };

  const onLogin = () => {
    if (captchaToken) {
      dispatch(
        requestLogin({ consentValue, sendRudderstackEvent, captchaToken })
      );
    } else {
      alert("Please complete the CAPTCHA");
    }
  };

  return (
    <Container>
      <BlueBackground>
        <LeftSide>
          <LogoContainer>
            <LogoWrapper>
              <Image
                style={{
                  width: "100px",
                }}
                fileName="logo-large"
              />
            </LogoWrapper>
          </LogoContainer>
          <ContentWrapper>
            <Header />
            <Subheader />
          </ContentWrapper>
          <ButtonWrapper>
            <GoogleSignInButton onClick={onLogin} />
          </ButtonWrapper>
          <Agreement />
          <div style={{ marginTop: 50 }}>
            <ReCAPTCHA
              sitekey={CAPTCHA_KEY_V2}
              onChange={handleCaptchaChange}
            />
          </div>
        </LeftSide>
      </BlueBackground>
      <WhiteBackground>
        <RightSide>
          <Image style={{ width: "100%" }} fileName="login-splash" />
        </RightSide>
      </WhiteBackground>
    </Container>
  );
};
