import { useDispatch } from "react-redux";
import { AppDispatch } from "store";
import { requestLogout } from "modules/authentication";
import { useEffect } from "react";
import { useSelectIsAuthentication } from "modules/authentication";
import { useNavigate } from "react-router-dom";

export const Signout = () => {
  const dispatch: AppDispatch = useDispatch();
  const isAuthenticated = useSelectIsAuthentication();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(requestLogout());
  }, [dispatch]);

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/signin", { replace: true });
      localStorage.removeItem("eventQueue");
    }
  }, [isAuthenticated, navigate]);

  return null;
};
