import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import store from "./store";
import App from "./App";
import "./index.css";
import { theme } from "themes";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const UITheme = createTheme({
  palette: {
    primary: {
      main: theme.colors.blue,
    },
    secondary: {
      main: theme.colors.black,
    },
  },
});

const root = (ReactDOM as any).createRoot(document.getElementById("root"));
root.render(
  // causes double render in development env
  <React.StrictMode>
    <ThemeProvider theme={UITheme}>
      <Provider store={store}>
        <App />
      </Provider>
    </ThemeProvider>
  </React.StrictMode>
);
