const MAXIMIZE_IMAGE = "maximize";
const PERSON = "person";
const COMPUTER = "computer";
const GRID = "grid";
const QUESTION_MARK = "question";
const LOGIN_SPLASH = "login-splash";
const LOGO = "logo";
const SEND = "send";
const BOOK = "book";
const CHROME = "chrome";

export {
  MAXIMIZE_IMAGE,
  PERSON,
  GRID,
  COMPUTER,
  QUESTION_MARK,
  LOGIN_SPLASH,
  LOGO,
  BOOK,
  CHROME,
  SEND,
};
